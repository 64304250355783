.footer_sec {
    background: #000466;
    padding: 1rem 1.8rem;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
}

.footer_icn {
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer_icn a {color:#fff;text-decoration: none;}
.fotter_line {
    height: 40px;
    width: 2px;
    background: #282DA3;
}


@media (max-width:991px) {
    .footer {
        display: none;
    }
}