.auction_box {
    width: 525px;
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 12px #0000002F;
    border-radius: 10px;
    column-gap: 20px;
    padding: 20px;
    margin-left: 10px;
    /* min-height: 326px; */
    min-height: 266px;
    /* min-height: 284px; */
    position: relative;
}
._btn_counter {
    position: absolute;
    top: -14px;
    right: -5px;
    width: 25px;
    height: 25px;
    background: #fa7732;
    border-radius: 100px;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.eye_icon_tooltip {
    position: absolute;
    bottom: 15px;
    right: 20px;
    color: #000564;
}
._tooltip {
    position: absolute;
    background: #000564;
    color: #fff;
    width: 82%;
    padding: 12px;
    border-radius: 10px;
    font-size: 13px;
    bottom: 36px;
    right: 12px;
    display: none;
}
.eye_icon_tooltip svg {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.auction_grid {
    display: flex;
    /* grid-template-columns: auto auto; */
    padding: 3rem 0;
    row-gap: 3rem
}

.auction_width {
    width: 100%;
    text-align: left;

}

.display_text {
    display: flex;
    justify-content: space-between;
}

.size_text {
    color: #3C3A3A;
    font-size: 22px;
    width: 230px;
    text-align: left;
    /* overflow: hidden; */
    /* height: 64px; */
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word; */

    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis
}
.auction_width span.disabled .edit_icn ,
.item_txt.disabled .setup_txt,
.list_data .list_item ul li.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.item_txt {
    font-size: 16px;
    color: #3C3A3A;
    margin-bottom: 15px;
}
.item_txt .auction_icn img{
    width: 17px;
    margin-right: 7px;
}
.apparel_txt {
    color: #3C3A3A;
    font-size: 22px;
}

.setup_txt {
    color: #313131;
    font-size: 20px;

}
.setup_txt.disabled{
    opacity: 0.5;
}

.product_btn , .product_btn:hover {
    background: #FFFFFF !important;
    border: 1px solid #313131;
    border-radius: 21px;
    width: 132px;
    font-size: 18px !important;
    color: #313131 !important;
    padding: 18px 20px !important;
    margin-top: 20px;
    line-height: 0;
}

.go_live_btn {
    width: 132px;
    font-size: 19px;
    padding: 8px 0;
    margin-top: 20px;
    background: #F17721 !important;
    border: 1px solid #F17721;
    color: #fff !important;
}

.btn_line {
    background: #d3d3d3;
    height: 1.5px;
    width: 100%;
    margin: 0 11px 11px auto;
    justify-content: flex-start;

}
.btn_line .btn:first-child{
margin-right: 20px;
}
.display_text .item_txt .checkbox{
    margin-right: 6px;
}
/* .auction_box_height_scroll {
    height: calc(89vh - (0px + 30px));
    overflow: auto;
} */
.dashboard_inner {
    padding: 1rem 1.8rem;
}
.cstm_modal_product .modal-content {
    max-width: 100%;
    border-radius: 29px;
    background: #FFFFFF;

}

.cstm_modal_edit_product .modal-content {
    width: 930px;
    background: #FFFFFF;
    border-radius: 27px;
    padding: 30px;
    border: 0;


}

.cstm_modal_product .modal-body {
    padding: 0;
}


.cstm_modal_edit_product .modal-header {
    border-bottom: 0;

}

.add_prdct_btn {
    width: 155px;
    padding: 7px;

}

.add_prduct_list {
    display: flex;
    align-items: center;
    color: #171717;
    font-size: 22px;
    column-gap: 4rem;
    padding: 20px 30px;

}

.list_wrp {
    display: flex;
    align-items: center;
    color: #3C3A3A;
    font-size: 20px;
    column-gap: 3.6rem;
    background: #D9D9F8;
    padding: 10px 30px;

}
.list_wrp span {
    width: calc(100% / 4 );
}
.nxt_btn {
    width: 111px;
    padding: 6px 0;
}
.nxt_btn svg{
    width: 40px;
    height: 40px
}

.list_item {
    display: flex;
    align-items: center;
    margin: 12px 0;
    border-bottom: 1px solid #205EC11C;
}


.list_item ul {
    /* column-count:5; */
    display: flex;
    width: 100%;
    align-items: center;
}
.list_item ul li:last-child, .list_item ul li:nth-child(3), .list_item ul li:nth-child(4) {
    text-align: center;
}
.list_item li {
    text-decoration: none;
    list-style: none;
}
.list_item ul li {
    width: calc( 100% / 4 );
    word-break: break-all;
}

.list_item ul li:last-child{
cursor: pointer;
}
.list_data {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #848484;
    font-size: 20px;
    margin-bottom: 20px;

}



.list_name {
    display: flex;
    justify-content: space-between;
    /* column-gap: 10rem; */
    width: 200px;

}

.text_left {
    text-align: left;
    width: 100%;
}


/* edit auction */
.schedl_action {
    color: #171717;
    font-size: 22px;
    text-align: center;
    display: block;
    margin-bottom: 20px;

}

.auction_store_img {
    margin-bottom: 2rem;
    display: flex;
}
.auction_store_selected_img{
   margin-bottom: 2rem;

}

.auction_store_img label {

    cursor: pointer;
    /* height: 238px;
    width: 143px; */
   /* --- square---- */
    width:162px;
    height:162px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin: auto;
    background: #FFEADB;
    border: 1px solid #F07721;
    border-radius: 5px;
    margin-bottom: 12px;

}



.auction_store_img input[type="file"] {
    display: none;
}



.auction_pls_icn {
    background: #F07721;
    color: #ffffff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: absolute;
    cursor: pointer;
    right: -14px;
    bottom: 8px;
    font-size: 28px;
    color: #ffffff;
}
.auction_pls_icn.avenir_medium._edit svg {
    width: 13px;
    height: 13px;
}
.description_inpt {
    width: 829px;
}

.category {
    font-size: 20px;
    color: #171717;
    margin-bottom: 2px;
    margin-top: 30px;
}

.choose_category {
    color: #898E9E;
    font-size: 18px
}

.radio_inpt {
    width: 18px;
    height: 18px;
    accent-color: #00057D;
    margin-right: 15px;
}
._radio_box {
    display: flex;
    align-items: center;
    margin-right: 60px;
}
.wrap_radio {
    display: flex;
    justify-content: flex-start;
    color: #171717;
    font-size: 18px;
    margin-bottom: 2rem;
    flex-direction: column;

}
.wrap_radio div{
    display: flex;
    align-items: center;
    margin-right: 60px;
    font-weight: 500;
}

/* add product list */
.cstm_modal_product_list .modal-content {
    max-width: 1059px;
    background: #FFFFFF;
    border-radius: 27px;
    padding: 30px;
    border: 0;

}

.cstm_modal_product_list .modal-dialog {
    max-width: 1059px;

}

.cstm_modal_product_list .modal-body {

    padding: 0;

}


.wrp_product_list {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    width: calc(10);
    width: calc(100% - 10px);
    overflow: auto;
}

.product_img {
    width: 158px;
    height: 238px;

    border-radius: 5px;

}

.product_img img {
    width: 158px;
    height: 238px;
    min-height: 238px;
    border-radius: 5px;

}

.product_name {
    color: #0F1624;
    font-size: 15px;
}

.product_size {
    color: #BABECB;
    font-size: 14px;
}

.product_price {
    color: #BABECB;
    font-size: 18px;
}

.product_active_price {
    color: #F07721;
    font-size: 18px;


}

.user_img {
    width: 170px;
    height: 286px;
}
.auction_box .user_img{
    width: 170px;
    height: 170px;
}
.user_img img {
    /* width: 170px;
    height: 286px;
    min-height: 286px; */
    width: 162px;
    height: 162px;
    min-height: 162px;
    border-radius: 10px;
}
._checked_detail{
    color:green;
    font-size: 15px;
    margin-bottom: 0;
    text-align: left;
    display: flex
}
._checked_detail .tickCross svg {
    width: 18px;
    height: 18px;
    fill: #dc3545;
}
._checked_detail .tickCheck{
    
        content: "";
        display: block;
        top: 4px;
        margin-left: 10px;
        width: 6px;
        height: 14px;
        border: solid green;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
     
}
.no_auction {
    color: #5A5959;
    font-size: 22px;
    margin: 6rem 0
}
.btn_scroll_arrow {
    background: #fff;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 100px;
    box-shadow: 0px 0px 8px #888;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}
.btn_prev {
    left: 15px;
}
.btn_next {
    right: 15px;
}
._time span.avenir_heavy{
    display: block;
    width: 100% !important;
}

._time{position: relative;}

._time .react-datepicker__input-container input ,
._time._disabled .react-datepicker-wrapper{
    width: 190px;
}

@media (max-width:991px) {
    ._time span.avenir_heavy{
        margin-top: 30px;
    }
    .cstm_modal_edit_product input{
        width: 100%;
    }
    .wrap_radio{flex-wrap: wrap;}
    .wrap_radio div {
        width: calc(100% );
        margin-right: 10px;
        flex-wrap: nowrap;
    } 
    .wrap_radio div input.radio_inpt{
        width: auto !important;
    }
    ._time .react-datepicker-wrapper,
    ._time .react-datepicker__input-container,
    ._time .react-datepicker__input-container input{
        width: 100% !important;
        display: block !important;
        
    }
    /* ._time .react-datepicker__input-container input,
    ._time .react-datepicker__input-container input
    {
      width: 190px !important;
    } */
   
    .cstm_modal_edit_product.modal .modal-dialog ,
    .cstm_modal_edit_product .modal-content {
        width: 100%;
        max-width:100%
    }
    .dashboard_container {
        height: calc(100dvh - 120px);
    }
    .dashboard_inner {
        padding: 1rem ;
    }
    .auction_grid {
        grid-template-columns: 3;
        padding: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 1.5rem;
    }
    .btn{
        width: auto !important;
    }
    .auction_box {
        width: 100%;
        padding: 10px;
    }

    .user_img,
    .user_img img {
        width: 107px;
        height: 107px;
        min-height: 107px;

    }

    .size_text {
        font-size: 14px;
    }

    .item_txt,
    .setup_txt {
        font-size: 13px;
    }

    .apparel_txt {
        font-size: 15px;
    }

    .product_btn,
    .go_live_btn {

        width: 70px;
        font-size: 12px;
        padding: 10px 0;

        margin-top: 10px;
    }

    .auction_icn img {
        width: 14px;
        height: 14px;

    }

    .btn_line {
        margin-top: 5px;
    }

    .edit_icn {
        width: 17px;
        height: 17px;
    }
}
@media (min-width:767px) and (max-width:991px) {
    .auction_grid {
        justify-content: center;
    }
    .auction_box {
        width: 600px;
            min-height: 198px;
        margin: 10px 20px 10px 20px !important;
    }
}
@media (min-width:320px) and (max-width:480px) {
    .list_data , .list_wrp{
        font-size: 16px;
    }
    .eye_icon_tooltip{
        bottom: -2px;
    }
    .nxt_btn svg {
        width: 20px;
        height: 20px;
    }
    .list_data .list_item li img{
        width: 16px;
        height: 16px;
    }
    .add_prduct_list{
        flex-wrap: wrap;
        justify-content: center;
    }
    .add_prduct_list button{
        margin-bottom: 20px;
        font-size: 16px !important;
        padding: 9px 20px !important;
    }
    ._top_bar {
        margin: 0 12px; 
    }
    


}
input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url('../assets/date.svg');
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: url('../assets/clock.svg');
  }


  ._date._form_with_icon._disabled .form-control:disabled {
    background-color: #e9ecef00;
    opacity: 0.5;
}