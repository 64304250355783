@import './placeholder.scss';
.sidebar_box {
    /* height: 881px; */
    height: calc(100vh - 89px);
    position: relative;
    width: 100%;
    max-width: 100%;
    background: #000467;
    overflow-y: auto;
}


.menu_list {
    padding-top: 25px;
    padding-left: 90px;
}


.side_list img,
.side_list_active img {
    width: 20px;
    height: 20px;
}

.side_list {
    width: 100%;
    display: flex;
    height: 70px;
    /* padding: 0px 95px; */
    gap: 20px;
    cursor: pointer;
    font-size: 18px;
    color: #FFFFFF;
    align-items: center;
    padding-left: 5rem;
    border-top: 1px solid #dcdcdc38;
}


.sidebar_box a {
    text-decoration: none;
    color: #ffffff;
}

.side_list_active a {
    text-decoration: none;
    color: #e0067a;

}

.side_list_active {
    width: 100%;
    display: flex;
    /* padding: 0px 95px; */
    height: 70px;
    gap: 20px;
    border-right: 5px solid #F17721;
    align-items: center;
    color: #FFFFFF;
    font-size: 18px;
    background: #00057D;
    padding-left: 5rem;border-top: 1px solid #dcdcdc38;

}


/* Header */

.header_wrp {
    display: flex;
    justify-content: space-between;
    padding: 1.3rem 5rem;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 3px 3px 20px #195AD61C;
}

.header_usrname {
    color: #171717;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.more_btn {
    display: none;
}

@media (max-width:991px) {
    /* mobile sidebar */
.mobile_sidebar {
    width: 100%;
    height: calc( 100vh - 62px );
    position: absolute;
    top: 0;
    overflow: hidden;
}

.person_info {
    background: #00057D;
    color: #fff;
    position: relative;
}
.person_info:after {
    content: '';
    background: #00057D;
    position: absolute;
    width: 100%;
    height: 29px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    bottom: -28px;
    left: 0;
    right: 0;
}
.person_info ._user {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #c6c8f042;
    border-bottom: 1px solid #c6c8f042;
}
.person_info ._user h5 span svg{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.person_info ._user ._icon svg{
    width: 22px;
    height: 22px;
}
.person_info p{
    margin: 12px 20px;   
}
._sidebar {
    margin-top: 30px;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
    background: #ffffff;
    height: calc( 100vh - 277px );
}
._sidebar ._list{
    background: #fff;
    border-bottom: 1px solid #f0772157;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
}
._sidebar ._list ._iner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
._sidebar ._list ._iner._active {
    color: #f07721;
}

    .header_wrp {
        /* display: none; */
        padding: 10px;
    }

    .header_logo {
        display: none;
    }

    .header_usrname {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        font-size: 15px;
    }

    .header_usrname img {
        height: 20px;
        width: 20px;
    }

    .dashboard_left {
        max-width: 100%;
    }

    .sidebar_box {
        height: 100%;
        position: fixed;
        width: 100%;
        max-width: 100%;
        max-height: 57px;
        bottom: 0;
        /* max-width: 100%; */
        /* position: fixed;
        bottom: 0; */
        /* max-height: 100px; */
        display: flex;
        padding: 10px 10px 0;
        text-align: center;
        background: #ffffff;
        column-gap: 15px;
        box-shadow: 3px -3px 23px 5px #195AD61C;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        justify-content: space-between;
    }

    .side_list {
        display: flex;
        padding: 0px;
        font-size: 12px;
        color: #C5C5C5;
        text-align: center;
        gap: 0;
        flex-direction: column;
        row-gap: 5px;
        background: #ffffff;
        height: max-content;


    }

    .side_list img {
        filter: contrast(0.4);
    }

    .side_list_active {
        display: flex;
        text-align: center;
        background: #ffffff;
        border-bottom: 2px solid #F0771F;
        border-right: 0;
        font-size: 12px;
        height: max-content;
        padding: 0px;
        color: #F0771F;
        flex-direction: column;
        row-gap: 5px;
    }

    .more_btn {
        display: flex;
        flex-direction: column;
    }

    .hide_navigation {
        display: none;
    }


}
._notification ._notification_box .dropdown_item a{text-decoration: none;}
._notification{
    .dropdown .btn:first-child:active {
        color: transparent;
        background-color: transparent;
        border-color: transparent;
    }
    .dropdown-toggle::after {
        display: none
    }
  ._notification_box{
    padding: 0;
    width: 468px;
    height: 600px;
    // overflow-y: auto;
    // overflow-x: hidden;
    ._heading {
        background: #000466;
        padding: 15px;
        text-align: center;
        color: #fff;
        h5{
            margin-bottom: 0;
        }
    }
    .dropdown_item{
        background: #F7F7FF;
        border: 1px solid #E5E5F8;
        border-radius: 8px;
        margin: 15px;
        width: calc(100% - 30px );
        padding: 12px;
        cursor: default;
        h5{
            color: #434343;
            font-size: 1.1rem;
            margin-bottom: 0;
            span{
                font-weight: 300;word-break: break-all;
                white-space: normal;
            }
        }
        h4{
            font-size: 1.1rem;
            margin-bottom: 4px;
            margin-top: 4px;
            color: #545454;
            @extend %break_word;
            span{
                svg{
                    fill:#F17721;
                }
            }
        }
        p{
            color: #888;
            font-size:0.8rem;
            margin-bottom: 0;
        }
    }
    ._body{
        width: 100%;
    height: calc(100% - 60px );
    overflow-y: auto;
    overflow-x: hidden;
    }
  }
}
// ._notification{
//     ._notification_box{
//       .dropdown-item{
//           h5{
//               span{}
//           }
//           h4{
//               span{}
//           }
//           p{
              
//           }
//       }
//     }
//   }

@media (max-width:991px){
    ._notification{
        ._notification_box{
            width: 320px;
        }
    }
}