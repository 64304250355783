
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  /* overflow-y: hidden; */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #F17721;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 37, 219, 0.628); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.form-control::placeholder{
  color:#898E9E !important
}
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../public/font/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../public/font/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../public/font/AvenirLTStd-Black.woff2') format('woff2'),
    url('../public/font/AvenirLTStd-Black.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../public/font/AvenirLTStd-Heavy.woff2') format('woff2'),
    url('../public/font/AvenirLTStd-Heavy.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.avenir_heavy {
  font-family: 'Avenir LT Std';
  font-weight: 500;
}



.avenir_medium {
  font-family: 'Avenir LT Std';
  font-weight: 400;

}

.avenir_black {
  font-family: 'Avenir LT Std';
  font-weight: 600;
}









.common_input_field {
  width: 424px;
  border-bottom: 2px solid #E8ECF7;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  /* color: #747474; */
  color: #000;
  font-size: 18px;
  padding-bottom: 2px;




}

/* select input */

.select_flag {
  width: 100px;
  border-bottom: 2px solid #E8ECF7;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #3C3A3A;
  font-size: 18px;
  background: #FFFFFF;
  padding-bottom: 7px;
}

.common_button {
  width: 426px;
  outline: none;
  border: none;
  border-radius: 32px !important;
  background: #F17721 !important;
  opacity: 1 !important;
  font-size: 19px !important;
  color: #FFFFFF !important;
  text-align: center;
  line-height: 1.5;
  padding: 14px 20px  !important;
  cursor: pointer;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  text-decoration: none;
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
}
.lower_case{
  text-transform: capitalize !important;
}
textarea{
  resize: none !important
}
.transparaent_button {
  color: #00057D;
  font-size: 16px;
  border: 1px solid #00057D;
  border-radius: 30px;
  background: #FFFFFF;
}

.form-control ,
.form-select ,
._time .react-datepicker__input-container input{
  border: none  !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #E8ECF7 !important;
  /* color: #898E9E !important; */
  color: #000 !important;
  padding: 0.375rem 0.3rem 0.3rem !important

}
._time .react-datepicker__input-container ,
._time .react-datepicker__input-container input{
  z-index: 99;
  background: #ffc0cb00;
  cursor: pointer;
}
.form-select{
    --bs-form-select-bg-img: url('./assets/arrow.svg') !important;
}
.form_box label{
  font-size: 18px;
  color: #898E9E;
}
.form_box h5{
  font-size: 20px;
  font-weight: 400;
}
._add_product_modal .form_box h5 {
    font-size: 18px;
}
.wrap_radio span {
  font-weight: 300;
}
.__fill_circle{
  width: 18px;
  height: 18px;
  background: #00057D;
  border-radius: 100px;
  margin-right: 12px;
  box-shadow: 0px 0px 3px #888;
}
.form_box h3{
  font-size: 22px;
    font-weight: 600;
}
.form-select:focus ,
.form-control:focus,
.react-datepicker-ignore-onclickoutside:focus-within,
.react-datepicker-ignore-onclickoutside:focus-visible,
.react-datepicker__input-container:focus-visible,
.react-datepicker__input-container input:focus-visible{
  box-shadow: 0 0 0 0rem rgba(13, 109, 253, 0) !important;
  /* border: none !important; */
  outline: none !important;
}
.react-datepicker__input-container:focus-visible,{

}
button:disabled {
  background: #EFF0F2 !important;
  border-radius: 32px !important;
  color: #BABABA !important;
  font-size: 19px !important;
  border: none;
  
}

.app_dashboard {
  display: flex;
  height: auto;

}

.app-header {
  height: 100px;
  background: red;
}

.dashboard {
  width: 100%;
  margin: 0 auto;
  /* display: flex; */
  /* height: 100vh; */
}

.dashboard_sidebar {
  /* width: 20%; */
  width:310px;
}

.dashboard_container {
  /* width: 80%; */
  width: calc(100% - 310px);
  margin: 0 auto;
  height: calc(100vh - 160px);
    overflow-y: auto;
}

/* .dashboard_left {

  width: 100%;
  max-width: 20%;
}

.dashboard_right {
  width: 100%;
  max-width: 80%;
  padding-left: 5rem;

} */

.header_sec {
  width: 100%;
}



/* Footer */

.footer {
  width: calc(100% - 310px);
    margin: 0 auto;
  /* padding-left: 20%; */
  position: fixed;
  /* top: 0; */
  bottom: 0;
}

.cursor_pointer {
  cursor: pointer;
}
.spinner-border {
  --bs-spinner-width: 1.5rem !important;
  --bs-spinner-height: 1.5rem !important;
}
._blank_page{
  text-align: center;
  margin: 0 auto;
    height: calc(100vh - 210px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
._blank_page .text p {
  font-size: 20px;
  margin-bottom: 6px;
  color: #5e5e5e;
  
}
.loader_body {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #0000008c;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cm_modal {
  position: fixed;
  background: #00000069;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.live_section.cm_modal_open {
  overflow: hidden;
}
._winner_animation {
  width: 100%;
  text-align: center;

}
._winner_animation h1{
  color:#fff;
  font-size: 45px;
}
.show_mobile{display: none;}
.show_desktop{
 display: block; 
}
/* display: flex;
justify-content: space-between;
color: #171717;
font-size: 18px;
margin-bottom: 2rem; */
.radio_btn{
    width: 18px;
    height: 18px;
    accent-color: #00057D;
    margin-right: 15px;
}
._order_container ._filter_box .dropdown-menu .dropdown-item {
  border-bottom: 0;
}
._filter_box .dropdown-menu .dropdown-item.menuitem1:last-child  ,
._filter_box .dropdown-menu .dropdown-item.menuitem1.menuitem2:last-child{
  padding: 15px;
  border-bottom: 1px solid #EAEAEB;
}
.cstm_modal_product_list .product_container {
  min-height: 375px;
}
.header_usrname img {
  border-radius: 8px;
  box-shadow: 0px 0px 6px #eee;
}

.header_usrname_anchor {
  text-decoration: none;
    color: inherit;
}
.cstm_modal_product_list .product_container .product_box ._image{
  width: 100%;
 
}
._time .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only .react-datepicker-time__header {
  color: #02057D;
}
._time .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
  background: #00067914;
  color: #02057D;
}
._time  .react-datepicker.react-datepicker--time-only {
  border-color: #02057D;
}
._time .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  stroke: #02057D;
}
._time {
  position: relative;
}
._time ._clock_icon {
  position: absolute;
  bottom: 10px;
  right: 25px;
  z-index: 9;
  cursor: pointer;
}
._time  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background: #02057D !important
}
._time._disabled span.avenir_heavy, 
._time._disabled .react-datepicker-wrapper, 
._time._disabled ._clock_icon svg {
  cursor: not-allowed;
  opacity: 0.4;
}
._time._disabled .react-datepicker__input-container input{
  cursor: not-allowed;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
  background: rgb(79 79 79 / 90%) !important;
}
.react-confirm-alert-body > h1 {
  font-size: 26px !important;
  color:#000 !important
}
.react-confirm-alert-button-group button {
  background: #F17721 !important;
  color: #fff !important;
  font-weight: 600  !important;
}
.react-confirm-alert-body {
  border-radius: 29px !important;
}
._time .react-datepicker-popper {
  left: 70px !important;
  z-index: 99
}
svg.react-datepicker__triangle{display: none;}
.setup_popup_parent h4{
  font-size: 24px;
  font-weight: 600;
}
.setup_popup_parent p{
  font-size:20px;
  margin-bottom: 40px;
}
.setup_popup{
display: flex;
/* padding: 40px; */
}
.setup_popup .form-control {
  border-bottom: none !important;
}
.setup_popup .form-select {
  border: 2px solid #E8ECF7 !important;
  padding: 0.8rem 0.6rem !important;
  margin: 15px 0;
}
.setup_popup .form-control label{
  font-size: 22px;
    color: #3C3A3A;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.setup_popup .form-control label ._icon{
  color:#878DA2 ;
  margin-right: 12px;
}
.setup_popup ._left{
  width: 507px;
  height: 276px;
  background: #313131 0% 0% no-repeat padding-box;
  border-radius: 26px;
  position: relative;
}
.setup_popup ._left video{
  width: 100%;
    height: 100%;
    border-radius: 26px;
    object-fit: cover;
}
.setup_popup ._left ._bottom{
  position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}
.setup_popup ._right{
  width: calc(100% - 507px - 40px);
    margin-left: 40px;
}
.setup_popup ._right .btn{
  width: auto !important;
  padding: 24px 35px !important;
}
.tick {
  width: 25px;
  display: block;
  height: 25px;
  position: absolute;
  background: #fc7834;
  top: -8px;
  right: -10px;
  z-index: 9;
  border-radius: 100px;
}
.tick:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 10px;
  width: 6px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
button.btn.disabled{
  opacity: 0.5 !important;
}
.__disabled {
  opacity: 1 !important;
  background: #EFF0F2 !important;
  color: #BABABA !important;
  border: none;
}
.tick_transparent_bg{
  position: absolute;
    background: #ffc0cb00;
    height: 238px;
    width: 162px;
    display: block;
    top: -1px;
    z-index: 9;
    left: 40.5%;
    top: 70px;
    cursor: pointer;
}
/* privacy policy */

.privacy_policy {
  margin: 38px 60px;word-spacing: 5px;
  padding-bottom: 60px;

}
.privacy_policy hr {
  border-color: #00000057;
  margin: 2rem 0rem;
}
.privacy_policy_parent .header {
  box-shadow: 0px 6px 4px #0000000f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px  60px;
}

.privacy_policy_parent .privacy_policy .content{
  margin-left: 20px;
  margin-top: 10px;
}

.privacy_policy_parent .privacy_policy .content .inner_content{
  margin-left: 20px;
  margin-top: 10px;
}


.privacy_policy_parent .header span svg{
  cursor: pointer;
}
._live_loader{
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  font-size: 18px;

}

._form_with_icon .react-datepicker-wrapper svg {
  position: absolute;
  right: 2px;
  fill: #00057d;
}
._date._form_with_icon .react-datepicker-popper {
  left: -8px !important;
}
._error__msz{
  font-size: 17px;
}
.__store_body .verify_gst_no {
  right: 40px;
}


@media (max-width:991px) {
  .privacy_policy {
    margin: 15px;
    word-spacing: 5px;
}
.privacy_policy_parent .header {
  padding: 20px 15px;
}
  .setup_popup {
    flex-wrap: wrap;
}
.setup_popup ._right{
  width: 100%;
  margin-top:20px;
margin-left: 0;}
  ._time .react-datepicker-popper {
    left: 90px !important;
}
  .live_section.cm_modal_open{
    /* height: calc(100dvh); */
  }
  .show_desktop{
    display: none; 
   }
   .show_mobile{display: block;}
  .common_input_field,
  .common_button {
    width: 100%;
    font-size: 14px;
  }

  .transparaent_button {
    display: none;
  }

  .dashboard {
    width: 100%;
  }

  .dashboard_sidebar {
    width: auto;
  }

  .dashboard_container {
    width: 100%;
  }
}

@media (max-width:360px) {

  /* .common_input_field,
  .common_button {
    width: 320px;
  } */
}

/* loader */

.loader{
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1:before, #loader-1:after{
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #F17721;
}

#loader-1:before{
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after{
  border: 10px solid #ccc;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader_body {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #0000005e;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.time-picker-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

._setup_leader.spinner-border {
  position: absolute;
  top: 34%;
  left: 47%;
  color: #fff;
  width: 40px;
  height: 40px;
}

input::-webkit-credentials-auto-fill-button {
  display: none;
  visibility: hidden;
}

.phone_rotate svg{
  transform: rotate(111deg);
}

.muteIcon svg{
width: 50px !important; 
height: 50px !important;
}

.me-2{
  width: 50px;
  height: 50px;
}