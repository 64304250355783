%align-cneter{
    display: flex;
    align-items: center;
    justify-content: center;
}
%dflex-space-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
%align_cneter_only{
    display: flex;
    align-items: center;
}



%ul-liststyle-padding-none{
    list-style:none;
    padding-left: 0px;
}
%break-flex{
    display: flex;
    flex-wrap: wrap
}
%img-wh{
    width: 100% !important;
    height: 100% !important;
}
%dflex_justify_between_only{
    display: flex;
    justify-content: space-between;
}

%break_word{
    word-break: break-all;
    white-space: normal;
}
