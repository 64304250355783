
._winner_loties {
  position: relative;
  /* background: #fff;
  padding: 53px 34px;
  border-radius: 20px; */
}



.rolling-names-container {
  text-align: center;
  /* margin-top: 50px; */
}

.names-wrapper {
  height: 86px; /* Adjust height of the rolling container */
  width: 200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: pink; */
}

.name {
  font-size: 1.2em;
  height: 35px; /* Adjust height of each name slot */
  line-height: 50px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.7s ease;
  color:#fff
}

.name.center {
  opacity: 1;
  /* font-weight: bold; */
}

.names-wrapper.rolling .name {
  animation: roll 0.8s infinite linear;
}

@keyframes roll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100% * (100 - 1)));/* Adjust 5 to the number of names */
  }
}

.winner {
  font-size: 1.2em;
  font-weight: bold;
  position: absolute;
  top: 38%; /* Center vertically within the names-wrapper */
  left: 50%; /* Center horizontally within the names-wrapper */
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 100%;
}
