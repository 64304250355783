@import './placeholder.scss';
.filtered_container{
    margin-top: -20px;
    margin-bottom: 20px;

    span {
        background: #F4F5F5;
        font-size: 12px;
        padding: 10px 13px;
        margin: 4px;
        border: 1px solid #E8E8E8;
        border-radius: 100px;
        position: relative;
        i{
            background: #838383;
            width: 15px;
            height: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            position: absolute;
            top: -7px;
            color: #fff;
            padding: 3px;
            cursor: pointer;
            right: 1px;
        }
    }
}
.product_container{
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
    -ms-overflow-style: none;
    

    .btn_scroll_arrow{
        top:44%
    }
    .product_box{
        // background: pink;
        // min-width: 185px;
        // min-height: 315px;
        min-width: 190px;
    min-height: 190px;
        margin: 10px;
        cursor: pointer;
        ._image{
            width: 180px;
            height: 180px;
            // width: 158px;
            // height: 238px;
            position: relative;
            ._icon {
                position: absolute;
                right: 9px;
                top: 8px;
            }
            img{
                width: 100%; 
                height: 100%;
                // object-fit: cover;
                border-radius: 5px;
            }
        }
        ._text{
            padding: 8px ;
            h6 {
                overflow: hidden;
                text-overflow: ellipsis;
                // display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            p{margin-bottom: 0;}
            ._size{
                font-size: 14px;
                color: #BABECB;
            }
            ._price{
                font-size: 18px;
                span{
                    color: #F07721;
                    font-weight: 600;
                }
            }
        }
       ._sold{
        position: absolute;
        z-index: 9;
        font-size: 12px;
        background: #fa7732;
        padding: 2px 8px;
        color: #fff;
       }
       
        
    }
    & ._disabled{
      cursor: not-allowed;
    }
    & .active{
        position: relative;
        border: 3px solid #fc7834;
        border-radius: 7px;
        ._image{
            width: 100%;
        }
        .tick {
            width: 25px;
            display: block;
            height: 25px;
            position: absolute;
            background: #fc7834;
            top: -8px;
            right: -10px;
            z-index: 9;
            border-radius: 100px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 4px;
                left: 10px;
                width: 6px;
                height: 14px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
           
        }
       
    }
    &::-webkit-scrollbar {
        width: 0; /* Hide the scrollbar in WebKit browsers */
        display: none
    }
}

._add_product_modal{
    padding: 30px 70px;
    .upld_img label {
        height: 240px;
        width: 162px;
    }
}
._product_detail_modal{
    .modal-dialog{
        padding: 30px;
        .modal-header{
            background: #D9D9F8;
            border-radius: 27px 27px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                // background: #ffd6e2;
                width: calc(100%/3);
                h4{
                    font-size: 24px;
                    margin-bottom: 0; 
                }
                .btn-edit, .btn-edit:hover {
                    width: 115px;
                    border-radius: 100px;
                    border-color: #030A7E;
                    color: #030A7E;
                    background: transparent;
                    // margin: 0 auto;
                    ._icon{margin-bottom: 5px;}
                }

            }
            ._btn{
                 display: flex;
                    align-items: center;
                    justify-content: flex-end;
                
               .btn{
                display: flex;
                align-items: center;
                justify-content: center
               }
               ._icon svg {
                width: 20px;
                height: 20px;
                margin-left: 6px;
            }
            }
        }
        .modal-body{
            padding: 30px;
            ._detail_page{
                display: flex;
              ._image{
                width: 191px;
                height: 191px;
                // height: 338px;
                margin-right: 40px;
                img{
                    @extend %img-wh;
                    border-radius: 6px;
                    // object-fit: cover;
                }
              }
              ._text{
                width: calc(100% - 200px);
                p{
                    padding: 16px 20px;
                    margin-bottom: 0;
                    border-bottom: 2px solid #e7e7e775;
                    display: flex;
                    word-wrap: break-word;
                    span:first-child {
                        width: 170px;
                        display: block;
                        padding-right: 16px;
                        font-weight: 300;
                    }
                    span:last-child {
                        width: calc(100% - 170px);
                        padding-left: 16px;
                        display: block;
                        font-weight: 500;
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                }
                
              }
            }
        }
  }
}


@media (max-width:991px){
    .cstm_modal_edit_product{
        .modal-dialog{
            .modal-body{
                .social-input{
                    position: relative;
                    svg{
                        position: absolute;
                        right: 10px;
                    }
                }
                .wrp_state{
                    flex-wrap: wrap;
                    div{
                        width: 100%;
                        .state_inpt{width: 100%;}
                    }
                }
            }
        }
    }
    ._product_detail_modal{
        .modal-dialog{
            .modal-header{
            // display: none !important
            background: #d9d9f800;
            flex-wrap: wrap;
            div{
                width: 100%;
                text-align: center;
                p{
                    display: inline-block;
                }
                h4{margin-top: 10px;}
            }
            }
            ._btn{justify-content: center !important;margin-top: 16px;}
            .modal-body{
                ._detail_page{
                    ._image {
                        margin: 40px auto;
                    }
                }
               
            }
    }
     .modal-body{
        padding: 0 !important;
        ._detail_page{
            flex-wrap: wrap;
        
            ._image{
                width: 319px !important;
                margin: 0 auto;
                height: 232px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain
                }
            }
            ._text{
                margin-top: 20px;
                width: 100% !important;
                p{
                    padding: 17px 20px;
                    margin-bottom: 0;
                    &:nth-child(odd){
                        background: #F7F7F7
                    }
                }
            }
        }
     }
    }
    .product_container{
     flex-wrap:wrap;
     .product_box {
        min-width: 154px;
        min-height: 154px;
        ._image {
            width: 170px;
            height: 170px;
        }
     }
    }
    
}  

@media (max-width:767px){
    ._add_product_modal{
        padding: 30px 20px;
    }
}