.video-background {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
  }
/* HTML: <div class="loader"></div> */
.loader1 {
  position: absolute;
  left: 50%;
  top: 50%;
  // transform: translate(-50%, -50%);
  --d:22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #fff;
 
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d))      calc(1*var(--d))     0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d))     calc(0*var(--d))     0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d))      calc(-1*var(--d))    0 6px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {transform: rotate(1turn)}
}
  .video-foreground,
  .video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;;
    pointer-events: none;
  }
  
  @media (min-aspect-ratio: 16/9) {
    .video-foreground {
      height: 300%;
      top: -100%;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-foreground {
      width: 400%;
      left: -150%;
    }
  }
  @media all and (max-width: 600px) {
    .vid-info {
      width: 50%;
      padding: 0.5rem;
    }
    .vid-info h1 {
      margin-bottom: 0.2rem;
    }
  }
  @media all and (max-width: 500px) {
    .vid-info .acronym {
      display: none;
    }
  }
  