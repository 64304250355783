.auth_main_section {
    display: flex;
    width: 100%;
    height: 100vh;


}

.logo_section {
    padding-left: 4rem;
}

.sign_in_heading {
    font-size: 28px;
    color: #171717;
}

.left_side {
    background-color: #161C84;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    row-gap: 10rem;
    width: 40%;

}


.right_side {
    padding: 5rem 8rem;
    width: 60%;
}

.right_section_width {
    width: 100%;
    max-width: 450px;

}

.position_relative {

    position: relative;
}

.eye_icon {
    position: absolute;
    right: 40px;
    top: -5px;

}

.welcome_txt {
    font-size: 22px;
    margin: 20px 0px 50px;
}

.wrap_inpt {
    margin-top: 40px;
    text-align: left;

}

.wrap_btn {
    margin: 70px 0 20px;
}

.forget_txt {
    color: #898E9E;
    font-size: 16px;
    text-align: right;
    padding-right: 4rem;
}

.dont_have_acc {
    color: #898E9E;
    font-size: 20px;
    margin-top: 6rem;
    text-align: center;
}

.register {
    color: #F17721;
    text-decoration: underline;
    cursor: pointer;
}


.reset {
    color: #171717;
    font-size: 16px;
    /* text-decoration: none; */

}

.name_wrap {
    display: flex;
    gap: 10px;
}

.name_inpt {
    width: 204px;
}

.wrap_option {
    display: flex;
}

/* .number_inpt {
    width: 345px;

} */
.number_inpt {
    width: 424px;
    padding-left: 60px;
}

.county_code {
    position: absolute;
    bottom: 4px;
    font-family: 'Avenir LT Std';
    /* font-weight: 500; */
    font-size: 18px;
}

input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 19px;
    height: 19px;
    top: -3px;
    left: 0;
    border: 2px solid #9D9D9D;
    border-radius: 3px;
    background-color: white;
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0px;
    left: 7px;
}


.checkbox {
    width: 19px;
}


.wrp_chekbox {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #9D9D9D;
    font-size: 14px;
}

.wrp_chekbox a {
    color: #fc7834;
    font-size: 14px;

}


.sign_in_girl {
    position: relative;
    top: -9rem;
}

/* forget flow */
.reset_text {
    font-size: 30px;
    color: #171717;
}

.reset_section {
    width: 100%;
    max-width: 700px;
    margin: 4rem 0;
}

.reset_number_inpt {
    width: 355px;

}

.eye_icn_new_password {
    position: absolute;
    right: 40px;
    top: 10px;

}

.reset_pass_section {
    width: 100%;
    max-width: 450px;
    margin: 4rem 0;
}

.number_text {
    color: #898E9E;
    font-size: 20px;
}

.edit_txt {
    color: #1C1C1C;
}

.verify_section {
    width: 100%;
    max-width: 500px;
    margin: 4rem 0;
    text-align: center;
}

.dont_rec_code {
    font-size: 20px;
    color: #262626;
    /* text-decoration: underline; */
}

.otp_wrp {
    display: flex;
    justify-content: center;
}

.opt_inp {
    display: flex !important;
    justify-content: center !important;
    width: calc(100% / 4 - 10px);
    margin: 15px;
}

.opt_inp input {
    display: flex !important;
    justify-content: center !important;
    width: 70px !important;
    color: #1A1A1A;
    position: relative;
    border-radius: 10px;
    font-size: 29px;
    outline: none;
    height: 86px !important;
    margin-top: 0px;
    border: 1px solid #ffffff80;
    border-radius: 3px;
    opacity: 1;
    margin-bottom: 10px;
    box-shadow: 0px 0px 8px #0B709529;
    border-radius: 6px;
    text-align: center !important;
}

.timer {
    font-size: 22px;
    color: #262626;
}


/* modal */
.cstm_modal .modal-content {
    max-width: max-content;
    border-radius: 24px;
    background: #FFFFFF;
    padding: 5rem 7rem;
}

.password_txt {
    color: #171717;
    font-size: 20px;
    margin-bottom: 2rem;
    text-align: center;
}


.signup_bg_img {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;

}

.signup_bg_img img {
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;

}

.logo_wrp {
    padding: 2rem 6rem 0rem;
}

.signup_process {
    /* text-align: center; */
    margin: 0 auto;
    max-width: max-content;

}

.complete_profile_txt {
    color: #FFFFFF;
    font-size: 28px;
    margin: 0 0 15px;
    text-align: center;
}

.signup_modal {
    background: #FFFFFF;
    padding: 2rem 4rem;
    border: 1px solid #707070;
    border-radius: 24px;
    margin: 2rem 0;
    width: 800px;
}
.signup_modal._no_width {
    width: auto;
}
.textbox_leftpading{
 padding-left:   60px !important;
}
.profile__step_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

._sub_header {
    color: #fc7834;
}

.profile__step_header ._icon svg {
    width: 27px;
    height: 27px;
    cursor: pointer;
}

.profile__step_header ._icon svg polygon {
    fill: #000;
}

/* personal info */
.signup_stps_txt {
    color: #171717;
    font-size: 22px;
    margin: 0 0 25px;
    display: block;
    text-align: center;

}

.wrp_personl_info {
    background: #F6F7FB;
    border: 1px solid #E8ECF7;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    padding: 20px 25px 50px;
    box-shadow: 0px 0px 7px #080d6e8f;
    margin-bottom: 7px;
}

.wrp_personl_info .personal_inpt {
    width: 100%;
}

.personal_inpt {
    width: 565px;
    background: #F6F7FB;
    color: #3C3A3A;
    font-size: 18px;
    padding: 30px 3px 0;
}

.icon_input {
    position: relative;
}

.icon_input svg {
    position: absolute;
    top: 56%;
    left: 0;
    fill: #111375;
}

.icon_input .personal_inpt {
    padding-left: 26px;
}

.usrname_txt {
    width: 705px;
    padding: 30px 3px 0;
    color: #3C3A3A;
    font-size: 18px;
    /* margin: 0 10px; */

}

.usrnm_err {
    text-align: left;
    margin: 0 auto;
    font-size: 15px;
}

.inline_error_state {
    display: flex;
    max-width: 100%;
    column-gap: 35px;
}

.inline_error_wrp {
    display: flex;
    column-gap: 4rem;
}

.inline_error_wrp_name {
    display: flex;
    max-width: 100%;
    column-gap: 10px;
    font-size: 14px;
}


.name_width {
    width: 45%;
    text-align: left;position: relative;
}

._validtion_icon svg{
        position: absolute;
        right: 20px;
        top: -24px;
        width: 18px;
        height: 18px;
      
}
.store_error {
    text-align: left;

    font-size: 16px;
}

.choose_usrnam {
    font-size: 16px;
    color: #B7B7B7;
    padding: 25px 27px 20px;
    text-align: left;
    line-height: 2;

}

/* bank acc */
.acc_details {
    font-size: 16px;
    color: #898E9E;
    text-align: center;

}

.bank_img {
    width: 50px;
    height: 50px;
}

.bank_img img {
    width: 50px;
    height: 50px;

}

.usr_details {
    font-size: 16px;
    color: #A09F9F;
    text-align: center;
}

.ifsc_txt {
    width: 565px;
    padding: 15px 3px 0;
    color: #000;
    font-size: 16px;

}

.ifsc_margin_bottom {
    margin-bottom: 12rem;

}

.acc_checkbox {
    text-align: left;
    display: flex;
    gap: 10px;
}

.form-group {
    display: block;
    width: 200px;
    background: #FFFFFF;
    border: 1px solid #DFDFE1;
    border-radius: 5px;
    margin: 15px 0px 25px;
    padding: 2px 7px 5px;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    color: #898E9E;
    font-size: 16px;

}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #00057D;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    background: #00057D;
    border-radius: 3px;
}

.acc_checkbox .form-group label::before {
    background: #00057d00;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.acc_checkbox .form-group input:checked+label:before {
    background: #00057D;
}

.acc_type_txt {
    color: #898E9E;
    font-size: 16px;
    text-align: left;
    display: block;
}

/* categories_wrap */
.categories_wrap {
    display: flex;
    gap: 1.5rem;
    overflow-x: auto;
    margin-bottom: 20px;
}

.categories_img {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    margin: 6rem 0 4rem  0;
}

.categories_img img {
    width: 148px;
    height: 148px;
    border-radius: 5px;

}

.categories_name {
    color: #171717;
    font-size: 18px;
    padding-top: 12px;
}

.categories_img_active {
    width: 153px;
    height: 153px;
    border: 4px solid #F07721;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 1px 10px #fc783485;
}

.categories_img_active ._icon {
    position: absolute;
    top: -13px;
    right: -11px;
    width: 23px;
    height: 23px;
    background: #fff;
    border-radius: 100px;
}

.categories_img_active ._icon svg {
    width: 25px;
    height: 25px;
    fill: #fc7834;
}

.categories_img_active img {
    width: 145px;
    height: 145px;
    border-radius: 0px;

}

/* store info */
.wrp_state {
    display: flex;
    gap: 20px;
}

.placeholder_img {
    height: 40px;
    min-height: 40px;
    width: 40px;
}

.placeholder_img img {
    height: 40px;
    min-height: 40px;
    width: 40px;
}

.state_inpt {
    border-bottom: 2px solid #E8ECF7;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #898E9E;
    font-size: 16px;
    width: 260px;
    background: #FFFFFF;
    margin-top: 2rem;

}

.stor_inpt_txt {
    width: 565px;
    font-size: 16px;
    color: #000;
}
.__store_body .wrap_inpt input ,
.__store_body .stor_inpt_txt{
    font-size: 16px;
    width: 96%;
}
.__store_body  .wrp_state ._first {
    width: 46.5%;
}
.__store_body .wrp_state ._first  select.state_inpt {
width: 100%;
}
.number_inpt_store.number_inpt,.state_inpt{
    color: #000;  
}
.or_line {
    margin: 0 auto;
    width: 90%;
    height: 1px;
    background: #cdcdcd;
}

.or_txt {
    margin: auto;
    position: relative;
    top: -18px;
    background: #ffffff;
    width: 70px;
    display: block;
    font-size: 20px;
    text-align: center;
}

.number_inpt_store {
    /* width: 465px; */
    width: 565px;
    font-size: 16px;
    color: #898E9E;

}

.verify_gst_no {
    position: relative;
    top: -35px;
    color: #171717;
    text-align: right;
    font-size: 18px;
    right: 20px;
    cursor: pointer;
    float: right;
    text-decoration: underline;
    text-transform: uppercase;
}

.cursor_pointer {
    cursor: pointer;
}

.phn_verify {
    position: relative;
    right: 30px;
}

.verify_phn_no {
    position: absolute;
    top: 0px;
    color: #171717;
    text-align: right;
    font-size: 18px;
    right: 40px;
    text-decoration: underline;

}

.use_location {
    background: #FFFFFF !important;
    border: 2px solid #00057D !important;
    border-radius: 25px;
    color: #00057D !important;
    font-size: 16px !important;
    width: 309px;


}

.last_name_err {
    padding-left: 8.5rem;
}

.wrp_chekbox_store {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.wrp_chekbox_store input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.wrp_chekbox_store label {
    position: relative;
    cursor: pointer;
    color: #262626;
    font-size: 16px;

}

.wrp_chekbox_store label:before {
    content: '';
    background-color: transparent;
    border: 1px solid #8D8D8D;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 11px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 3px;
}

.wrp_chekbox_store input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #262626;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox_store {
    width: 24px;
}

.upld_img label {

    cursor: pointer;
    height: 72px;
    width: 77px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin: auto;
    background: #FFEADB;
    border: 1px solid #F07721;
    border-radius: 5px;
    margin-bottom: 12px;

}

.selected_img {
    width: 73px;
    height: 68px;
}

.auction_store_img .selected_img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.date-input-wrapper {
    position: relative;
}

.date-input-wrapper input[type="date"] {
    width: 100%;
    padding-right: 30px; /* Adjust this value based on your calendar icon size */
}

.date-input-wrapper::after {
    content: "\1F4C5"; /* Unicode for calendar emoji, you can replace this with your own icon */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* This ensures clicks pass through to the input */
}
.selected_img img {
    height: 70px;
    width: 72px;
    min-height: 70px;
    border-radius: 5px;
}

.upld_img input[type="file"] {
    display: none;
}




.pls_icn {
    background: #F07721;
    color: #ffffff;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: absolute;
    cursor: pointer;
    right: -9px;
    bottom: 8px;
}



.upload_store_logo {
    color: #F07721;
    font-size: 16px;
    /* position: relative;
    top: -10px; */
    text-align: center;
    display: block;


}

.upload_store_logo_danger {
    color: rgb(224 55 71) !important;
    font-size: 16px;
    /* position: relative;
    top: -10px; */
    text-align: center;
    display: block;


}

.verify_num_modal .modal-content {
    max-width: max-content;
    border-radius: 24px;
    background: #FFFFFF;
    padding: 3rem 5rem;
    margin: auto;
    text-align: center;
}

.vrfy_num {
    font-size: 28px;
    color: #171717;
}

.code_snd {
    color: #3C3A3A;
    font-size: 18px;
}


.mob_signup_logo {
    display: none;
}

.process_1 {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.bank_detail {
    display: flex;
    background: #F6F7FB;
    align-items: flex-start;
    width: 506px;
    padding: 10px;
    column-gap: 20px;
    margin: 20px 0;
    align-items: center;
}

.bank_name {
    font-size: 16px;
    color: #3C3A3A;
    margin-bottom: 0px;
}

.bank_branch {
    font-size: 14px;
    color: #BABABA;
    margin-bottom: 0;

}

/* .crop_img {
    height: 400px;
    width: 580px;
    min-height: 400px;
    position: relative;
    top: -85px;
    text-align: center;
} */
.crop_img {
    /* height: 383px;
    width: 408px;
    min-height: 383px;
    position: relative;
    top: -85px; */
    text-align: center;
    margin: 0 auto;
}
.new_crop_btn button{
    /* position: absolute;
    top: -59px;
    left: -52px;
    z-index: 999; */
}
.new_crop_btn button.btn.done_btn.common_button.avenir_heavy {
    padding: 6px !important;
    width: 82px;
    font-size: 14px !important;
}
/* .crop_img .ReactCrop {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: pink;
}
.crop_img .ReactCrop div{
    width: 100%;
    height: 100%;
} */
/* ----new one---- */














.done_btn {
    width: 131px;
    padding: 5px 0;
    position: relative;
    /* margin-top: -140px; */
}

/* .crop_img {
  width: 300px;
  height: 300px; 
  margin: 20px auto;
  overflow: hidden;
  position: relative;
}

.crop_img .ReactCrop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crop_img .ReactCrop__image {
  max-width: none;
  max-height: none;
}

.crop_img .ReactCrop__crop-selection {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
} */
.__store_body .county_code {
    font-size: 16px ;
  }

@media (max-width:991px) {
    .phn_verify {
        position: absolute;
        right: 20px;
        top: 0px;
    }
    .__store_body .social-input .stor_inpt_txt {
        width: 94%;
        padding-right: 10px;
    }
    .profile__step_header ._icon svg {
        width: 18px;
        height: 18px;

    }

    .mob_signup_logo {
        display: block;
        margin: 2rem 0 1rem;

    }

    .mob_signup_logo img {
        width: 125px;
        height: 79px;
    }

    .left_side {
        display: none;
    }

    .right_side {
        width: 100%;
        padding: 1rem;
        text-align: center;

    }

    .right_section_width {
        width: 100%;
        /* max-width: max-content; */
        margin: 0 auto;
    }

    .sign_in_heading {
        text-align: center;
        font-size: 22px;
    }


    .welcome_txt {
        text-align: center;
        font-size: 18px;
    }

    .cstm_modal .modal-content {
        padding: 0;
    }

    .cstm_modal .modal-backdrop.show {
        opacity: 1;

    }

    .verify_num_modal.modal-backdrop.show {
        opacity: 0;
    }

    .name_inpt {
        width: 50%;

    }

    .wrap_option {
        /* padding-left: 18px;   */
        justify-content: left;
    }

    .inline_error_state {
        column-gap: 50px;
        font-size: 14px;
    }

    .number_inpt {
        width: 100%;
        padding-left: 55px
    }

    .county_code {
        font-size: 13px;
        bottom: 4px;
    }

    .name_wrap {
        justify-content: left;
    }

    .verify_num_modal .modal-content {
        padding: 10px;
        position: fixed;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        text-align: center;
        border: 0;
        box-shadow: 0px 0px 8px #0B709529;
        max-width: 100%;

    }


    .opt_inp input {
        /* display: flex !important; */
        justify-content: center !important;
        width: 43px !important;
        color: #1A1A1A;
        position: relative;
        border-radius: 10px;
        font-size: 24px;
        outline: none;
        height: 53px !important;
        margin-top: 0px;
        border: 1px solid #ffffff80;
        border-radius: 3px;
        opacity: 1;
        margin-bottom: 10px;
        box-shadow: 1px 1px 7px 4px #0B709529;
        border-radius: 6px;
        text-align: center !important;
    }

    .opt_inp {

        margin: 15px 0 8px 10px;
    }

    .otp_wrp {
        justify-content: center;

    }

    input[type=checkbox] {
        /* top: -14px;
        left: 6px; */
        top: -1px;
        left: 6px;

    }

    .wrp_chekbox {
        padding-left: 12px;
        text-align: left;
        column-gap: 13px;
    }

    .wrp_chekbox a,
    .wrp_chekbox {
        font-size: 14px;
    }

    .choose_usrnam {
        line-height: 20px;
        padding: 10px 0;
    }

    .wrap_btn {
        margin: 25px 0 20px;
    }

    .dont_have_acc {
        font-size: 16px;

    }

    .dont_rec_code,
    .timer,
    .number_text,
    .password_txt {
        font-size: 16px;

    }

    .vrfy_num {
        font-size: 18px;
    }

    .code_snd {
        font-size: 14px;
    }

    .reset_text {
        font-size: 24px;
        margin-top: 2rem;
    }

    .welcome_txt {
        font-size: 16px;
        margin-top: 10px;
    }

    .cstm_modal .modal-content {

        margin: 0 auto;
    }

    .signup_modal {
        padding: 2rem;
        width: 100%;
    }

    .personal_inpt,
    .usrname_txt {
        width: 310px;
    }

    .complete_profile_txt {
        font-size: 18px;
        margin: 6rem 0 2rem 0rem;


    }

    .logo_wrp {
        display: none;
    }




    .signup_bg_img img {
        z-index: 1;
        height: 215px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        top: 0px;
        position: absolute;
    }

    .signup_bg_img {

        position: absolute;
        width: 100%;
        z-index: 0;
        top: 0;

    }

    .complete_profile_txt {
        font-size: 18px;

        position: relative;
        top: -37px;
        z-index: 1;
    }

    .process_1 {

        position: relative;
        z-index: 1;
        top: -49px;

    }

    .signup_modal {
        position: relative;
        top: -10px;
        border: 0;
        padding: 2rem 1.5rem;
    }

    .signup_stps_txt {
        font-size: 18px;
    }

    .wrp_personl_info {
        padding: 20px 15px;
    }

    .stor_inpt_txt,
    .ifsc_txt {
        width: 325px;
    }

    .state_inpt {
        width: 142px;
    }

    .form-group {
        width: 144px;
    }

    .usr_details,
    .acc_details {
        font-size: 13px;
    }

    .categories_wrap {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 6rem;
    }

    .categories_img {
        margin: 1rem 0 0 0;
    }

    .categories_name {
        padding-top: 4px;
        font-size: 16px;
    }

    .inline_error_wrp {
        justify-content: center;
        column-gap: 1rem;
    }

    .left_align_error {
        text-align: left;
        font-size: 14px;
        /* padding-left: 25px; */
    }

    .inline_error_wrp_name {
        column-gap: 0;
        max-width: 360px;
    }

    .bank_detail {
        width: 330px;
    }

    .verify_phn_no {
        right: 16px;
        font-size: 14px;
    }

    .usrnm_err {
        margin: 0 18px;
        font-size: 14px;
    }

    .crop_img,
    .acc_checkbox img {
        width: 380px;
    }
}


@media (max-width:360px) {
    .personal_inpt {
        width: 280px;
    }

    .phn_verify {
        position: relative;
        right: -135px;
        top: -30px;
    }

    .inpt_loader {
        position: relative;
        right: -135px;
        top: -35px;
    }

    .bnk_inpt_loader {
        position: relative;
        top: -40px;
        right: -280px;

    }
}

@media(max-width:455px) {
    .crop_img {
        width: 100%;
        /* height: 200px;
        min-height: 200px; */

    }

    .crop_img img {
        height: 100%;
        width: 100%;
        min-height: 258px;
    }
}

.register.dont_rec_code {
    cursor: auto;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    width: 100px; 
  }
  input[type="date"] {
    width: 100%; 
  }  */
    