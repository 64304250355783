@import './placeholder.scss';
.box_section {
    /* padding-left: 20%; */
    text-align: center;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
   -ms-overflow-style: none;
}
  .box_section::-webkit-scrollbar ,
  ._stats_section::-webkit-scrollbar  ,
  ._live_section::-webkit-scrollbar ,
  .product_container::-webkit-scrollbar{
    width: 0; /* Hide the scrollbar in WebKit browsers */
    display: none
  }

.wrp_dash_btn {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    /* margin-top: 12rem; */
    /* width: 309px; */

}

.auction_btn {
    width: 309px;

}

.no_data {
    margin: 6rem 0 0 0;
}
._dasboard_section{
    .btn{
        min-width: 112px !important;
        width: auto !important;
        padding: 8px 30px !important;
    }
    ._pannel_group{
        text-align: left;
        ._tab_bn{
            min-width: 113px;
            height: 43px;
            background: #F7F7F7 ;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            color:#BAB4B4;
            margin-right: 15px;
        }
        & ._active{
            background: #F17721;
            color:#fff;
            font-weight: 500;
         } 

    }
    ._pannel_body{
        ._mobile_live_Section{display: none;}
        ._live_section{
            display: flex;
            // position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            ._live_card{
                min-width: 546px;
                min-height: 317px;
                margin-right: 22px;
                position: relative;
                max-width: 546px;
                max-height: 317px;
                ._baner_img{
                  width: 100%;
                  height: 100%;object-fit: cover;
                }
                ._top{
                    height: 38px;
                    background: #171717;
                    border-radius: 5px;
                    display: flex;
                    position: absolute;
                    top: 20px;
                    left: 28px;
                    padding: 8px 15px;
                    h4{
                        color: #fff;
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                    ._icon{
                        margin-top: -4px;
                        margin-right: 6px;
                        svg{
                            path{fill:#fff}
                        }
                    }
                }
                ._bottom{
                    @extend %dflex-space-between;
                    position: absolute;
                    bottom: 22px;
                    width: 100%;
                    left:22px;
                    right:22px;
                    ._left{
                        width: 60%;
                        color:#fff;
                        text-align: left;
                        z-index: 1;
                        p{margin-bottom: 0;font-size: 24px;font-weight: 300;}
                        h3{font-size: 22px;}
                       } 
                    ._right{
                     width: calc(100% - 60%);
                     
                    }
                 }
            }
            ._live_card:after{
                background-image: linear-gradient(#00000059, #ffffff24, #00000085);
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            } 
           
            
        }
        ._stats_section{
            display: flex;
            margin: 46px 0;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            // position: relative;
            .stats_card{
                min-width: 220px;
                max-width: 220px;
                min-height: 190px;
                background: #F7F7FF;
                border: 1px solid #E5E5F8;
                border-radius: 8px;
                margin-right: 22px;
                display: flex;
                // align-items: center;
                padding: 15px;
                ._center{
                    width: 100%;
                    .top{ 
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50%;
                    ._icon{
                     margin-right: 20px;
                    }
                    ._count{
                        font-size: 28px;
                        font-weight: 700;
                        word-break: break-word;
                    }
               }
                p{font-size: 18px;
                    min-height: 50%;
                    font-weight: 500;
                    text-align: left;
                    }
                }
               
            }
        }
        ._banner_section{
            display: flex;
            box-shadow: 0px 0px 12px #205EC11C;
            ._left{
                width: 50%;
                position: relative;
                background: #fff;
                .btn{
                    background: #fff !important;
                    color: #000 !important;
                    position: absolute;
                    bottom: 52px;
                    left: 52px;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            ._right{
                width: 50%;
                display: flex;
                align-items: center;
                
                ._center{
                    text-align: left;
                    padding-left: 52px;
                    h5{
                        font-size: 28px;
                        font-weight: 400;
                        // padding: 0 48px;
                        text-align: left;
                        margin-bottom: 25px;
                    }
                }
            }
        }
        ._graph_section{
            display: flex;
            ._left{
                width: 50%;
                min-height: 326px;
                background: #F7F7FF;
                box-shadow: 0px 0px 12px #205EC11C;
                padding: 25px;
                h3{
                    font-size: 28px;
                }
                ._box{
                    background: #fff;
                    box-shadow: 0px 0px 12px rgba(32, 94, 193, 0.1098039216);
                    display: flex;
                    ._white{padding: 12px;}
                    ._blue{padding: 12px;background: #02057D;color:#fff}
                }
                
            }
            ._right{
                width: 50%;
                height: 326px;
                background: #FFFFFF;
                box-shadow: 0px 0px 12px #205EC11C;
                
            }
        }
        ._product_section{
            
            .product_box{
                min-width: 185px;
                ._image{
                    width: 100%;
                    padding: 20px;
                }
            }
        }
        
    }
}
.search_box{
    position: relative;
    
    input{
        width: 403px;
    height: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    padding: 10px;
    padding-left: 30px;
    &:focus-visible{
        outline: 0;
    }
    }
    
    span{
        position: absolute;
        top: 46%;
        left: 10px;
        transform: translate(0, -50%);
    }
}
._scroll_part {
    height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
}
._filter_box{
    
  
    ._filter_box .show.dropdown .dropdown-toggle.btn ,
    .dropdown-togglee{
        width: 280px;
        border-radius: 0;
        border: none;
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
        
    }
    .dropdown-toggle::after{
            display: none;
    }
    
    .dropdown-togglee{
        padding: 13px;
        box-shadow: 0px 4px 4px #c7c7c7;
        margin-bottom: 12px;
        margin-top: 0;
        background: #f3f3f3;
    }
    .dropdown-menu{
        border: none;
        border-radius: 0;
        box-shadow: 0px 3px 8px #888;
        width: 280px;
        padding-top: 0px;
        // margin-top: -42px;
        margin-top: 17px;
        right: 0;
        .dropdown-item{
            &.active,
            &:hover{
                background-color: rgb(255, 255, 255);
            }
            .common_button{
              width: auto;
              line-height:0.4;
            }
            .form-group{
                margin: 0;
                padding: 0;
                width: 100%;
                background: #FFFFFF;
                border: 0;
                border-radius: 0;
                label{
                    color: #000;
                    white-space: pre-wrap;
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                    span{
                        display: block;
                        word-wrap: break-word;
                        width: calc(100% - 36px);
                        white-space: pre-wrap;
                    }
                    &:before{
                        margin-top: 4px;
                    }    
                }
               
                
            }
        
            padding: 15px;
            border-bottom: 1px solid #EAEAEB;
            &:last-child{
                border-bottom: 0;
            }
        }
       
    }
    .show.dropdown {
        .dropdown-toggle {
            z-index: 99999999;
            position: absolute;
            height: 40px;
            opacity: 0;
            right:0;
            width: 280px;
            span,
            svg{
                display: none;
            }
        }
    }
   &._filter_box_opened{
    position: absolute;
    right: 30px;
    top: -27px
   }
}
._top_bar{
    display: flex;
    justify-content:flex-end;
    .end_bar{
        display: flex;
    }
}
._setting_section{
    background: #F6F7FB;
    border: 1px solid #E1E5F4;
    border-radius: 5px;
    padding: 40px 26px;
    width: 95%;
    ._setting_box{
        height: 55px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 12px #0E397E2F;
        border-radius: 5px;
        margin-bottom: 8px;
        padding: 10px 40px;
        @extend %dflex-space-between;
        h6{
            color:#5D5D5D;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    .btn, .btn:hover{
        min-width: 200px !important;
        padding: 10px 20px !important;
        background: #F17721 !important;
        border: 1px solid #F17721;
        color:#FFFFFF !important;
    }
}
._store_section{
  @extend ._setting_section;
  h3{
    color:#F07721;
    font-size: 26px;
    font-weight: 600;
    span{
        font-size: 18px;
        font-weight: 500;
        color:#000;
    }
    ._light{
        color:#5F5F5F;
    }
  }
  p{
    font-size: 18px;
    color:#5F5F5F;
  }
  ._address{
    color:#000;
    font-weight: 500;
  }
}
._prsonal_detail_section{
    background: #F6F7FB;
    border: 1px solid #E1E5F4;
    border-radius: 5px;
    p{
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;
        padding: 25px 40px;
        border-bottom: 1px solid #E1E5F4;
        display: flex;
        align-items: center;
        .orange{
            color: #F0771F; 
            font-weight: 800;
        }
        ._icon {
            font-size: 22px;
            margin-right: 15px;
            color: #000563;
        }
    }
    
}
.btn.lower_case.common_button.btn-auto {
    width: auto !important;
}
._account_detail_section{
    background: #F6F7FB;
    border: 1px solid #E1E5F4;
    border-radius: 5px;
    padding: 50px 30px 40px;
    display: flex;
    flex-wrap: wrap;
    ._card{
        width: 336px;
        min-height: 182px;
        background: #fff;
        border: 1px solid #E1E5F4;
        margin: 9px;
        ._head{
            @extend %dflex-space-between;
            padding: 15px;
            ._logo{@extend %align-cneter;}
            ._right{
            span{
                svg{
        
                }
            } 
            }
        }
       
        ._body{
            padding:0 15px 15px 15px;
            ._light{color: #5f5f5fc2;font-weight: 400;}
            p{font-size: 15px;margin-bottom: 0px;font-weight: 500}
            .top{
                @extend %dflex-space-between;
                ._left{
    
                }
                ._right{
    
                }
            }
            ._bottom{
                
            }
        }
        &.active{
            border: 1px solid #ff0000;   
        }
    }
}

.btn_edit{
    width: auto !important;
padding: 8px 30px !important;
font-size: 20px !important;
border: 1px solid #00057D;
color: #00057D !important;
background: transparent !important
}
.salon_switch_container{
    display: flex;
    align-items: center;padding-left: 1rem;
    .switch {
        position: relative;
        display: inline-block;
        width: 43px;
        height: 22px;
        z-index: 1;
        input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #DEDDDD;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 17px;
            width: 18px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            transition: .4s;
          }
          &.round {
            border-radius: 34px;
          }
          &.round:before {
            border-radius: 50%;
          }
      }

    }
  
  input:checked + .slider {
    background-color: #F0771F;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #F0771F;
    -webkit-box-shadow: 0 0 1px #F0771F;
    -moz-box-shadow:0 0 1px #F0771F;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  .modal-content{
    // margin-top: 30px;
    .close_icon {
        position: absolute;
        top: 12px;
        right: 12px;

         span{
            svg{
                width: 36px;
                height: 36px;
                fill: #00000042;cursor: pointer;}
         }
    &._add_product{
        top: -14px;
        right: -14px
    }
    }
}
.img_placholder{
    text-align: center;
    p{
      font-size: 14px;
      font-weight: 500;  
      color:#F07721;
      margin-bottom: 0;
    }
     
}
.show_only_desktop{
    display: block;
}
.show_only_mobile{
    display: none;
}

@media (min-width:320px) and (max-width:423px) {
    .auction_box {
       min-height: 274px  !important;
    }
    ._account_detail_section {
        padding: 14px 10px 12px;
       ._card{ width: 100%;}
    }
    .react-confirm-alert-body {
       width: auto;
    }
}
@media (min-width:320px) and (max-width:385px){
    .auction_box {
         min-height: 300px  !important;
       .btn_line{
        .btn{
            width: 106px !important;
        }
       }
    }
   
}
@media (max-width:480px) {
    ._stats_section{
        .stats_card{
            ._center{
                .top{
                    ._icon{
                        margin: 0 auto;
                    }
                    flex-wrap: wrap;
                    ._count{
                        width: 100%;
                    }
                }
                p{text-align: center !important;}
            }
        }
    }
}
@media (max-width:991px) {
    .show_only_desktop{
        display: none;
    }
    .show_only_mobile{
        display: block;
    }
    .auction_store_img {
        flex-wrap: wrap;
    }
    .btn {
        font-size: 12px !important;
    }
    ._account_detail_section {
        justify-content: center;
    }
    ._store_section{
        position: relative;
        width: 100%;
        ._edit{
           svg{
            width: 30px;
            height: 28px;
            color: #00057D;
            position: absolute;
            top: 20px;
            right: 20px;
           }
        }
    }
    .btn_scroll_arrow.btn_next{
        display: none;
    }
    .box_section {
        width: 100%;

        text-align: center;
    }
    ._top_bar{
        .search_box {
            width: calc(100% - 63px);
            input{width: 100%;}
        }
    }
   
    .no_auction {
        font-size: 15px;
        margin: 2rem;

    }
    // -------convert modal in page----
    .modal-backdrop.___hide_modal_mobile.show{display: none;}
    .___hide_mobile_modals{
        margin-top: 62px;
        height: calc(100vh - 140px);
        .modal-dialog{
            margin: 0px 0 0 0;
            .modal-content{
                box-shadow: 0px 0px 0 rgba(32, 94, 193, 0.1098039216);
                border-radius: 0px;
                border: none;
                
            }
        }
    }
    ._product_detail_modal .modal-dialog{
        padding:0  !important;
        align-items: flex-start !important
    }
    .dashboard_inner.position_relative.__auction_parent{
        padding: 0;
        .box_section{
            .auction_grid {
                padding: 0px;
                .auction_box{
                    margin-left: 0px;
                    margin: 20px 20px 0px 20px;
                    min-height: 205px;
                    .auction_width{
                        .display_text{
                            flex-wrap: wrap !important;
                            .item_txt {
                                margin-bottom: 6px;
                                .checkbox{left:0}
                                .setup_txt{
                                    margin-left: 12px;
                                }
                               
                            }
                        }
                        .display_text.btn_line{
                            margin: 10px 0px !important; 
                           .btn{
                            font-size: 12px !important;
                            padding: 13px 15px !important;
                           }
                        }
                    }
                }
            }
        }
    }
    ._dasboard_section  {
        .btn{
            font-size: 14px !important;
            width: auto !important;
            padding: 8px 20px !important;
        }
        ._pannel_group{
            ._tab_bn {
                min-width: 62px;
                height: 32px;
                font-size: 11px;
                margin-right: 12px;
                &:last-child{
                    margin-right: 0px;
                }
            }
            
        }
        ._pannel_body{
            ._mobile_live_Section {
                display: block;
                margin-left: -15px;
                margin-right: -15px;
                .carousel{
                    .carousel-indicators{
                        bottom: -53px;
                       button{
                        width: 12px;
                        height: 12px;
                        background-color: #fc7834;
                        border-radius: 100%;
                       }
                    }
                    .carousel-inner{
                        .carousel-item{
                            width: 100%;
                            height: 275px;
                            position: relative;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            ._top{
                                background: #171717;
                                border-radius: 5px;
                                display: flex;
                                position: absolute;
                                top: 20px;
                                left: 28px;
                                padding: 8px 15px;
                                height: 30px;
                                ._icon {
                                    margin-top: -6px;
                                    margin-right: 6px;
                                    svg{
                                        path{fill: #fff}
                                    }
                                }
                                h4{font-size: 12px;
                                    color: #fff;
                                    margin-bottom: 0;}
                            }
                            .carousel-caption{
                                position: initial;
                                ._bottom{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    position: absolute;
                                    bottom: 22px;
                                    width: 100%;
                                    left:22px;
                                    right:22px;
                                    ._left{
                                        width: 60%;
                                        color:#fff;
                                        text-align: left;
                                        p{margin-bottom: 0;font-size: 19px;font-weight: 300;}
                                        h3{font-size: 18px;}
                                       } 
                                    ._right{
                                     width: calc(100% - 60%);
                                     
                                    }
                                }
                                h3{font-size: 21px; margin-bottom: 0;}
                                p{font-size: 20px; margin-bottom: 0;}
                            }
                        }
                    }
                    .carousel-control-prev{
                        display:none;
                    }
                    & .carousel-control-next{
                       @extend .carousel-control-prev
                      }
                }
            }
        ._live_section{
            display: none;
            ._live_card{
              
                ._baner_img{

                }
                ._top{
                    height: 30px;
                    ._icon {
                        margin-top: -6px;
                        margin-right: 6px;
                        svg{
                            width: 14px;
                            height: 14px}
                    }
                    h4 {
                        font-size: 12px;
                    }
                }
                ._bottom{
                    ._left{
                        p{font-size: 20px;}
                        h3{font-size: 21px;}
                    }
                }
               
               
            }
        }
        ._stats_section{
            margin-top: 60px;
            .stats_card{
                min-width: 135px;
                max-width: 135px;
                min-height: 160px;
                margin-right: 14px;
                ._center{
                    
                    .top{ 
                        ._count {
                            font-size: 22px;
                        }
                    ._icon{
                        width: 40px;
                        height: 40px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    ._count{
                        font-size: 22px;
                    }
               }
                p{font-size: 15px;
                    }
                }
               
            }
        }
        ._banner_section{
             flex-wrap: wrap;
             margin-left: -15px;
            margin-right: -15px;
            box-shadow: none;
            ._left{
                width: 100% ;
                background: #02057E;
                color:#fff;
                padding: 30px ;
                text-align: left;
                h1{
                    font-size: 22px;line-height: 1.8;
                }
                .btn{
                   position: initial;
                   background: #fff0 !important;
                    color: #fff !important;
                    border-color: #fff;
                    margin: 20px 0;
                }
                img{
                   
                }
            }
            ._right{
                width: 100% ;
                padding: 30px ;
                background: #F7F7FF;
                ._center{
                    padding-left:0;
                    h5{
                        font-weight:500 ; font-size: 22px;line-height: 1.8;
                    }
                }
            }
        }
        ._graph_section{
            flex-wrap: wrap;
            ._left{
                width: 100% ;
                min-height:auto;
                background: #fff;
                margin-bottom: 40px;
                h3{
                    font-size: 22px; 
                    margin-top: 0 !important;
                }
                ._box{
                  
                    ._white{padding: 8px;}
                    ._blue{padding: 8px;background: #02057D;color:#fff}
                }
                .wrap_radio{
                    ._radio_box{
                        span{
                            font-size: 12px;
                        }
                    }
                }
            }
            ._right{
                width: 100% ;
                
            }
        }
        ._product_section{
                .product_container {
                    flex-wrap: nowrap;
                
            h3{
                font-size: 22px; font-weight:400 
            }
        }
        }
        
    }
   }
    .dashboard_inner.position_relative.__auction_parent .box_section .auction_grid .auction_box .auction_width .auction_width.display_text.mb-2{flex-wrap: nowrap !important;
    span:first-child{
        width: 80%;
    word-break: break-all;
    }}
}

.highcharts-container svg text.highcharts-credits {
    display: none;
}

.orange_text{
    color: #F0771F; 
}

.react-multi-carousel-list.top_product_carousel button{
    border-color:#FF5722
}
.react-multi-carousel-list.top_product_carousel .react-multi-carousel-dot--active button{
    background:#FF5722
}
.react-multi-carousel-list.top_product_carousel .react-multiple-carousel__arrow{
    background: #fff;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 100px;
    box-shadow: 0px 0px 8px #888;
    display: none;
}
.react-multi-carousel-list.top_product_carousel .react-multiple-carousel__arrow::before{
    color:#000
}
.react-multi-carousel-list.top_product_carousel .react-multi-carousel-track{
    margin-bottom: 30px;
}
._winner_loties{
    position: relative;
    .lotie_layout {
        // position: absolute;
        // background: #880796;
        // width: 198px;
        // height: 75px;
        // bottom: 214px;
        // margin: 0 auto;
        // left: 143px;
        // background: #96074714;
        position: absolute;
        background: #880796;
        width: 193px;
        height: 89px;
        bottom: 201px;
        margin: 0 auto;
        left: 146px;
        display: flex;
        text-align: center;
        box-shadow: inset 0px 0px 13px #000;
        // span{
        //     background: #880796;
        //     width: 55px;
        //     height: 100%;
        //     margin: 0 auto;
        // }
        ._creaker{
                position: inherit;
                bottom: 66px
        }
    }
}



.name-roller {
    text-align: center;
    margin-top: 30px;
    height: 100%;
    width: 100%;
  }
  
  .roller {
    font-size: 1em;
    // height: 50px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin-bottom: 25px;
    color:#fff
  }
  
  .roller.rolling {
    animation: roll 0.4s linear infinite;
  }
  
  @keyframes roll {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  