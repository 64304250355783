@import './placeholder.scss';
.end_action{
    border: 1px solid #00057D;
    color: #00057D !important;
    background: transparent !important;
    width: auto;
    padding: 8px 30px !important;
    font-size: 18px !important;
}
.live_section{
    display: flex;
    padding: 30px 50px;
    padding-bottom: 0;
    height: calc(100dvh - 90px );
    // ._viewer_togle_btn{display: none;}
    ._left_section{
        // width: 381px;
        width: 32.5%;
        min-width: 382px;
        
        ._product_list_section{
            box-shadow: 0px 0px 12px #205EC11C;
            height: calc(100% - 351px );
            .product_list_tab{
                display: flex;
                ._tab{
                    width: 50%;
                    @extend %dflex-space-between;
                    background: #00057D;
                    color: #ffffffa3;
                    text-align: center;
                    padding: 10px 15px;
                    cursor: pointer;
                    h4{
                        font-size: 16px;
                        margin: 0 auto;
                        margin-bottom: 0;
                        font-weight: 400;
                        .add_product_lis_btn{
                            padding: 4px 8px;
                            background: #fd7331;
                            border-radius: 100px;
                            text-align: center;
                        }
                    }
                }
                & .active{
                    color: #fff;
                    background: #000467;
                }
            }
            ._header{
                @extend %dflex-space-between;
                background: #00057D;
                color: #fff;
                padding: 8px 15px;
                h4{
                    font-size: 21px;
                }
                    .product_list_btn{
                        width: auto !important;
                        font-size: 15px !important;
                        padding: 4px 20px !important;
                    }
                    
                }
            ._list{
                padding: 15px 15px;
                border-bottom: 1px solid #EDEDED;
                @extend %align_cneter_only;
                h6{margin-bottom: 0;color:#848484;font-weight: 400;word-break: break-all}
                ._first{
                    width: 55px;

                }
                ._laast{
                    width: 50px;
                    display: flex;
                    .nxt_btn {
                        width: 23px;
                        cursor: pointer;
                        svg{
                            width: 100%;
                        }
                    }
                }
                ._last{
                    position: relative;
                    width: calc(100% - 100px);
                    @extend %dflex-space-between;
                    
                   ._next{
                    background: #313131 !important;
                    width: auto !important;
                    font-size: 9px !important;
                    padding: 4px 20px !important;
                   } 
                }
                &._head{
                    h6{
                        color:#000;
                        font-weight: 500;
                    } 
                }
            }
            }
            ._list_section{
                height: calc(100vh - 333px - 190px);
                overflow-y: auto;
                overflow-x: hidden;
    
            }
        ._auction_details_section{
            height: 326px;
            ._video_bottom{
                ._first{
                    @extend %dflex-space-between;
                    // padding: 12px 12px 0;
                }
               ._middle{
                h5{
                    font-size: 16px;
                    font-weight: 600;
                    ._text{
                        color: #fd7331;
                        font-weight: 500;
                    }
                }
               }
               ._last{
                 .description{
                    h6 {
                        font-size: 17px;
                        font-weight: 400;
                        margin: 18px 0;
                        span{
                            font-weight: 500; 
                        }
                    }
                    p {
                        color: #BABABA;
                        font-size: 15px;
                    }
                   }
                   h5{
                    font-size: 19px;
                    margin-bottom: 25px;
                    ._ornage{
                        color:#F17721;
                    }
                   }
                   .btn_block{
                    @extend %dflex-space-between
                   }
               }
               
                .btn{
                    width: auto!important;
                    padding: 8px 30px !important;
                    font-size: 16px !important
                }
                ._pick{
                    border: 1px solid #00057D;
                    color: #00057D !important;
                    background: transparent !important;
                    
                    &:hover{ @extend ._pick }
                    
                }
               
                 
                }
        }
    }
    ._main_center{
        // width: calc(100% - 381px - 381px);
        width: 35%;
        margin: 0 10px;
        // background: lemonchiffon;
        ._video_section{
            width: 375px;
            margin: 0 auto;
            ._video{
                // height: 600px;
                height:calc( 100vh - 126px );
                background-color: rgb(0, 0, 0);
                width: 100%;
                position: relative;
                video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                ._live_svg{
                    position: absolute;
                    top: 18px;
                    right: 30px;
                }
                ._mute_icon_row{
                    position: absolute;
                    bottom: 20px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    svg{
                        width: 36px;
                        height: 36px;
                    }
                }
                
            }
            ._video_bottom{
            //   height: ;
            h5{
                font-size: 18px;
            }
            .btn{
                width: auto!important;
                padding: 8px 30px !important;
                font-size: 16px !important
            }
            ._pick{
                border: 1px solid #00057D;
                color: #00057D !important;
                background: transparent !important;
                
                &:hover{ @extend ._pick }
                
            }
            .broad_cast{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0 0 10px;
                ._typing_box{
                    height: 51px;
                    border: 1px solid #C7C7C7;
                    border-radius: 9px;
                    margin: 6px;
                    padding: 6px;
                    input {
                        border: none;
                        height: 40px;
                        width: calc(100% - 38px);outline: 0;
                    }
                    ._send_btn1 {
                        width: 38px;
                        height: 38px;
                        background: #F17721;
                        border-radius: 6px;
                        border: none;
                        svg{
                            margin-left: -4px;
                            margin-top: -4px
                            
                        }
                    }
                }
            }
            
              ._first{
                @extend %dflex-space-between;
                padding: 12px 12px 0;
                border-bottom: 2px solid #88888836;
                ._left{
                   
                }
                .btn_block{
                    
                    // ._pick{
                    //     border: 1px solid #00057D;
                    //     color: #00057D !important;
                    //     background: transparent !important;
                    //     &:hover{ @extend ._pick }
                        
                    // }
                    ._giveaway{margin-left:10px}
                }
              }
              ._second{
                padding: 12px;
                width: 100%;
                @extend %dflex_justify_between_only;
                p{
                    font-size: 16px;
                    color:#BABABA;
                }
                ._right{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: end;
                    width: 200px;
                    ._ornage{
                        color:#F17721
                    }
                    span.btn{
                        display: block;
                        width: auto !important;
                        min-width: 130px;
                        cursor: auto !important;
                    }
                }
              }
            }
            ._video_bottom_mobile{display: none;}
        }
    }
    ._heading {
        padding: 11px 20px 8px;
        margin-bottom: 14px;display: flex;
        align-items: center;justify-content: space-between;
        h4 {
            font-size: 18px;
            margin-bottom: 4px;
        }
        ._online {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 300;
            margin-left: 18px;
            ._dot {
                display: block;
                width: 10px;
                height: 10px;
                background: #04CC0C;
                border-radius: 100px;
                ._red {
                    background: #cc0404;
                }
            }
           
        }
    }
    ._right_section{
        ._viewer_section.show_mobile{display: block;}
        // width: 381px;
        position: relative;
        width: 32.5%;
        min-width: 382px;
        background: #fff;
        ._viewer_togle_btn{
            background: #FFFFFF;
            box-shadow: 0px 0px 12px rgba(32, 94, 193, 0.1098039216);
        }
        ._viewer_section{
            // width: 210px;
            background: #fff 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 12px #205EC11C;
            position: absolute;
            right: 10px;
            width: 188px;
            z-index: 999;
            top: 35px;
            ._heading{
                padding: 11px 20px 8px;
                margin-bottom: 14px;
                @extend %align_cneter_only;
                h4{
                    font-size: 18px;
                    margin-bottom: 4px;
                }
                ._online{
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 300;
                    margin-left: 18px;
                    ._dot{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: #04CC0C;
                        border-radius: 100px;
                    }
                    & ._red{
                        background: #cc0404;   
                    }
                }
            }
        }
        ._header{
            @extend %dflex-space-between;
            background: #00057D;
            color: #fff;
            padding: 8px 15px;
            h4{
                font-size: 21px;
            }
                .product_list_btn{
                    width: auto !important;
                    font-size: 15px !important;
                    padding: 4px 20px !important;
                }
                
            }
        // ._product_list_section{
        //     box-shadow: 0px 0px 12px #205EC11C;
            
        //     ._list{
        //         padding: 15px 15px;
        //         border-bottom: 1px solid #EDEDED;
        //         @extend %align_cneter_only;
        //         h6{margin-bottom: 0;color:#848484;font-weight: 400;word-break: break-all}
        //         ._first{
        //             width: 55px;

        //         }
        //         ._laast{
        //             width: 50px;
        //             display: flex;
        //             .nxt_btn {
        //                 width: 23px;
        //                 cursor: pointer;
        //                 svg{
        //                     width: 100%;
        //                 }
        //             }
        //         }
        //         ._last{
        //             position: relative;
        //             width: calc(100% - 100px);
        //             @extend %dflex-space-between;
                    
        //            ._next{
        //             background: #313131 !important;
        //             width: auto !important;
        //             font-size: 9px !important;
        //             padding: 4px 20px !important;
        //            } 
        //         }
        //         &._head{
        //             h6{
        //                 color:#000;
        //                 font-weight: 500;
        //             } 
        //         }
        //     }
        //     }
        ._live_comment_section{
            box-shadow: 0px 0px 12px #205EC11C;
            // height: 415px;
            height: calc(100% - 65px );
            .comments_section{
                height: calc(100% - 106px);
                overflow-y: auto;
                overflow-x: hidden;
                ._comment{
                    background: #F5F6F7 0% 0% ;
                    border: 1px solid #E8E8E8;
                    border-radius: 5px;
                    display: flex;
                    padding: 12px;
                    margin: 6px 12px;
                    ._user{
                        width: 28px;
                        height: 28px;
                        img{
                            width: 100%;
                            height: 100% ;
                            object-fit: fill;
                        } 
                    }
                    ._msz{
                        width: calc(100% - 28px);
                        margin-left: 16px;
                        h5{
                            font-size: 15px;
                            font-weight: 400;
                            display: flex;
                            justify-content: space-between;
                            
                            ._time{
                                color:#ACACAC;
                                font-size: 13px;
                            }
                        }
                        p{
                            font-size: 15px;
                            color: #7B7878; font-weight: 300;
                        }
                    }
                    
                }
            }
            .typing_prent{
                width: 100%;
                form{
                    width: 100%;
                ._typing_box{
                    height: 51px;
                    border: 1px solid #C7C7C7;
                    border-radius: 9px;
                    margin: 6px;
                    padding: 6px;
                    @extend %dflex-space-between;
                    input{
                        border: none;
                        height: 40px;
                        width: calc(100% - 38px );
                        &:focus-visible{
                            outline: 0;
                        }
                    }
                
                    ._send_btn{
                        width: 38px;
                        height: 38px;
                        background: #F17721;
                        border-radius: 6px;
                        border: none;
                    @extend %align-cneter;
                    svg{
                        fill: #fff;
                    }
                    
                    }
                }  
               }
            }
           
        }
       
        // ._list_section{
        //     height: calc(100vh - 412px - 190px);
        //     overflow-y: auto;
        //     overflow-x: hidden;

        // }
            
        }
       
    }
    
   
    @media (max-width:991px) {
        .hide_header_mobile{display: none  !important;}
        // .live_section{
        //     padding: 0px;
        //     position: relative;
        //     background: rgb(0 0 0 / 0%);
        //     height: calc(100dvh - 78px);
        //     ._left_section {
                
        //         width: 100%;
        //         min-width: 100%;
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         z-index: 9;
        //         ._auction_details_section{
        //             height: 0px;display: none;
        //         }
        //         ._product_list_section{
        //             background: #fff;
        //             height: calc(100%);
        //             border-radius: 15px 15px 0 0;
        //             min-height: 200px;

        //             .product_list_tab{
        //                 :first-child{
        //                     border-top-left-radius: 15px;
        //                 }
        //                 :last-child{
        //                     border-top-right-radius: 15px;
        //                 }
        //             }
        //             ._list_section{
        //                 height: auto;
        //             }
        //         }
        //     }
            
            
        //     ._viewer_togle_btn {
        //         width: 75px;
        //         height: 40px;
        //         background: #121212;
        //         border-radius: 20px;
        //         color: #fff;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         position: absolute;
        //         // z-index: 9;
        //         right: 12px;
        //         cursor: pointer;
        //         font-size: 11px;
        //         ._online{
        //             width: 9px;
        //             height: 9px;
        //             border-radius: 100px;
        //             background: #04CC0C;
        //         }
        //         ._icon{
        //             margin-left: 10px;
        //            svg{
        //             width: 20px; height: 17px
        //            }
        //         }
        //     }
        //     ._viewer_section {
           
        //         display: none; 
        //         position: absolute;
        //         right: 45px;
        //         width: 188px;
        //         z-index: 9;
        //         top: 51px;
        //         ._heading{
        //             h4{
        //                 font-size: 16px;
        //                 margin-bottom: 0;
                        
        //             }
        //         }
        //         ._viewer_list {
        //             height: 420px;
        //             ._viewer{
        //                 p{
        //                     font-size: 14px;
        //                 }

        //             }
        //         }
        //         &.show_mobile{
        //             display: block;
        //         }
        //     }
        //     ._main_center{
        //         width: 100%;
        //         margin: 0;
        //         ._video_section{
        //             position: relative;
        //             width: 100%;
        //             ._video{
        //                 height: calc(100vh - 82px);
        //             }
        //             ._video_bottom{
        //                display: none;
        //             }
        //             ._video_bottom_mobile{
        //                 display: block;
        //                 position: absolute;
        //                 bottom: 0px;
        //                 padding: 10px 0px;
        //                 width: 100%;
        //                 background: #00000045;
        //                 .btn{
        //                     width: auto !important;
        //                     font-size: 15px !important; 
        //                     padding: 7px 30px !important;
        //                     font-weight: 300 !important;
        //                 }
        //                 ._first{
        //                     ._left{
        //                         display: flex;
        //                         align-items: center;
        //                         justify-content: space-between;
        //                         color:#fff;
        //                         img{
        //                             width: 54px;
        //                         }
        //                         h5{font-size: 16px;}
        //                     }
        //                 }
        //                 ._second{
        //                     color:#fff;
        //                     ._right{
        //                         display: flex;
        //                         align-items: center;
        //                         justify-content: space-between;
        //                         h5{
        //                             font-size: 15px;
        //                             font-weight: 300;
        //                             opacity: 0.6;
        //                             span._ornage{
        //                                 color:#F17721;
        //                                 font-weight: 600;
        //                             }
        //                         }
        //                         .btn{
        //                             font-size: 13px !important; 
        //                             padding: 4px 16px !important;
        //                             background: #fff !important;
        //                             color:#000 !important;
        //                         }
        //                     }
        //                 }
        //                 .btn_block{
                           
        //                     ._pick{
        //                         background: #fff !important;
        //                         color:#000 !important;
        //                         font-weight: 400 !important;
        //                     }
        //                     ._giveaway{
        //                         font-weight: 400 !important;
        //                     }
        //                 }
        //             }
        //         }
        //     }
            
        //     ._right_section{
        //         // display: none;
        //             width: 100%;
        //             background: #fff0;
        //             position: absolute;
        //             bottom: 32px;
                
        //         ._live_comment_section{
        //             height: 350px;
        //             box-shadow:none;
        //             padding: 0 20px;
        //             ._header{display: none;}
        //             ._typing_box {
        //                 width: 90%;
        //                 border: 1px solid #fff;
        //                 input{
        //                     background: transparent;
        //                 }
        //             }
        //             ._three_dot{
        //                 svg{
        //                     width: 26px;
        //                     height: 26px;
        //                     fill: rgb(255, 255, 255);
        //                 }
        //             }
        //             .comments_section{
                        
        //                 ._comment{
        //                     color:#fff;
        //                     background: #12121242;
        //                     border: none;
        //                     ._msz{
        //                         margin-left: 0px;
        //                         p {
        //                             color: #ffffff;
        //                         }
        //                     }
        //                 }
        //             }

        //         }
        //         ._product_list_section{
        //             display: none;
        //         }
        //     }
        //     // ._left_section{display: none;}
        // }
        //----------live mobile page-----------------------------------
        ._live_mobile{
            position: relative;
            .live_section {
                padding: 0px 0px;
                padding-bottom: 0;
                height: calc(100dvh - 0px);
                overflow: hidden;
                .top_bar{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 94%;
                    left: 20px;
                    right: 20px;
                    z-index: 9;
                    top: 36px;
                    .btn{
                        border: 1px solid #fff;
                        color: #fff !important;
                        background: transparent !important;
                        width: auto;
                        padding: 8px 18px !important;
                        font-size: 14px !important;
                    }
                    
                }
                ._viewer_section {
                            display: none; 
                            position: absolute;
                            right: 45px;
                            width: 188px;
                            z-index: 9;
                            top: 51px;
                            background: #fff;
                            ._heading{
                                h4{
                                    font-size: 16px;
                                    margin-bottom: 0;
                                    
                                }
                            }
                            ._viewer_list {
                                // height: 420px;
                                ._viewer{
                                    p{
                                        font-size: 14px;
                                        width:calc(100% - 28px)
                                    }
            
                                }
                            }
                           
                            &.show_mobile{
                                display: block;
                            }
                        }
                
                ._live_comment_section {
                    position: absolute;
                    // bottom: 100px;
                }
            }
            ._left_section{
                width: 100%;
                background: #ffffff00;
                min-width: 100%;
                height: 220px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 9;
                ._auction_details_section{
                    height: 100%;
                    position: absolute;
                    width: 100%;
                    padding: 4px 20px;
                    // background: pink;
                    ._video_bottom{
                        .btn {
                            padding: 8px 18px !important;
                            font-size: 11px !important;
                        }
                        ._pick{
                            border: 1px solid #00057D;
                            color: #00057D !important;
                            background: white !important;
                        }
                        ._first{
                            
                        }
                        ._middle{
                            h5 {
                                font-size: 13px;
                                color: #fff;
                                margin: 0px 0px !important;
                            }
                        }
                        ._last{
                            .description{
                                h6{margin: 4px 0;
                                    color: #fff;
                                    font-size: 15px;}
                                p{margin-bottom: 4px;font-size: 12px;}
                            }
                            h5{
                                font-size: 14px;
                                margin-bottom: 4px;
                                color: #fff;
                            }
                            .btn_block{

                            }
                        }
                        
                    }
                }
                
                // 
            
            }
            .__mobile_product_list{
                &._product_list_section{
                    display: none;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: pink;
                        min-height: 180px;
                        z-index: 9;
                        background: #fff;
                        height: auto;
                        border-radius: 15px 15px 0 0;
                        min-height: 200px;
                        width: 100%;
                        ._cross_icon{
                            width: 30px;
                            height: 30px;
                            right: 47%;
                            top: -16px;
                        }
                        .product_list_tab{
                            display: flex;
                            padding: 1px;
                            ._tab{
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background: #00057D;
                                color: #ffffffa3;
                                text-align: center;
                                padding: 10px 15px;
                                cursor: pointer;
                                h4{
                                    font-size: 16px;
                                    margin: 0 auto;
                                    margin-bottom: 0;
                                    font-weight: 400;
                                    .add_product_lis_btn{
                                        padding: 4px 8px;
                                        background: #fd7331;
                                        border-radius: 100px;
                                        text-align: center;
                                    }
                                }
                            }
                            & .active{
                                color: #fff;
                                background: #000467;
                            }
                            
                            :first-child{
                                border-top-left-radius: 15px;
                            }
                            :last-child{
                                border-top-right-radius: 15px;
                            }
                           
                        }
                        ._header{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background: #00057D;
                            color: #fff;
                            padding: 8px 15px;
                            h4{
                                font-size: 21px;
                            }
                                .product_list_btn{
                                    width: auto !important;
                                    font-size: 15px !important;
                                    padding: 4px 20px !important;
                                }
                                
                            }
                        ._list{
                            padding: 15px 15px;
                            border-bottom: 1px solid #EDEDED;
                            display: flex;
                            align-items: center;
                            h6{margin-bottom: 0;color:#848484;font-weight: 400;word-break: break-all}
                            ._first{
                                width: 55px;
            
                            }
                            ._laast{
                                width: 50px;
                                display: flex;
                                .nxt_btn {
                                    width: 23px;
                                    cursor: pointer;
                                    svg{
                                        width: 100%;
                                    }
                                }
                            }
                            ._last{
                                position: relative;
                                width: calc(100% - 100px);
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                
                               ._next{
                                background: #313131 !important;
                                width: auto !important;
                                font-size: 9px !important;
                                padding: 4px 20px !important;
                               } 
                            }
                            &._head{
                                h6{
                                    color:#000;
                                    font-weight: 500;
                                } 
                            }
                        }
                    }
            }
            ._live_comment_section{
                height: 350px;
                width: 100%;
                box-shadow:none;
                padding: 0 20px;
                bottom: 185px;
                .comments_section{
                    height: calc(100% - 106px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    ._comment{
                        color:#fff;
                        background: #12121242;
                        border: none;
                        border-radius: 5px;
                        display: flex;
                        padding: 12px;
                        margin: 6px 12px;
                        ._user{
                            width: 28px;
                            height: 28px;
                            img{
                                width: 100%;
                                height: 100% ;
                                object-fit: fill;
                            } 
                        }
                        ._msz{
                            width: calc(100% - 28px);
                            margin-left: 0px;
                            h5{
                                font-size: 15px;
                                font-weight: 400;
                                display: flex;
                                justify-content: space-between;
                                
                                ._time{
                                    color:#ACACAC;
                                    font-size: 13px;
                                }
                            }
                            p{
                                font-size: 15px;
                                color: #ffffff;
                                 font-weight: 300;
                            }
                        }
                        
                    }
                }
                ._header{display: none;}
                .typing_prent{
                    width: 100%;
                    form{
                        width: 100%;
                    ._typing_box{
                        height: 51px;
                        border-radius: 9px;
                        margin: 6px;
                        padding: 6px;
                        width: 90%;
                        border: 1px solid #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        input{
                            border: none;
                            height: 40px;
                            width: calc(100% - 38px );
                            background: transparent;
                            color:#fff;
                            &:focus-visible{
                                outline: 0;
                            }
                        }
                    
                        ._send_btn{
                            width: 38px;
                            height: 38px;
                            background: #F17721;
                            border-radius: 6px;
                            border: none; display: flex;
                            align-items: center;
                            justify-content: center;
                        
                        svg{
                            fill: #fff;
                        }
                        
                        }
                    }  
                   
                   }
                   ._three_dot{
                    svg{
                        width: 26px;
                        height: 26px;
                        fill: rgb(255, 255, 255);
                    }
                  }
                }
            }
            ._viewer_togle_btn {
                 width: 75px;
                 height: 40px;
                 background: #121212;
                 border-radius: 20px;
                 color: #fff;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 position: absolute;
                 z-index: 9;
                 top: 50px;
                 right: 12px;
                 cursor: pointer;
                 font-size: 11px;
                 ._online{
                     width: 9px;
                     height: 9px;
                     border-radius: 100px;
                     background: #04CC0C;
                 }
                 ._icon{
                     margin-left: 10px;
                    svg{
                     width: 20px; height: 17px
                    }
                 }
             }
            ._main_center{
                width: 100%;
                margin: 0 0px;
                ._video_section{
                    width: 100%;
                    .cm_modal {
                        z-index: 9;
                    }
                    ._video{
                        height: calc(100vh - 6px);
                        ._mute_icon_row{
                            display: none;
                        }
                    }
                }
            }
         
        }
    }
    @media (min-width:992px) and (max-width:1300px) {
        .live_section{
            padding:20px;
            ._viewer_section{
              width: 100%;
            }
            ._main_center{
              width: calc(100% - 290px - 290px);
            }
            ._left_section {
                width: 32.5%;
                min-width:  32.5%;
            }
            ._right_section{
              width: 32.5%;
              min-width: 32.5%;
            }
          }
    }
    @media (min-width:320px) and (max-width:500px){
        
        ._live_mobile{
            ._left_section {
                height: 174px;
            }
            ._live_comment_section {
                height: 260px;
                bottom: 130px;
            }
         
    }
    }
    ._typing_box1 {
        display: flex;
        align-items: center;
        height: 51px;
        border: 1px solid #C7C7C7;
        border-radius: 9px;
        margin: 6px;
        padding: 6px;
      }
      
      ._broadcast_input1 {
        flex: 1;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 8px;
        font-size: 14px;
      }
      
      ._send_btn1 {
        padding: 8px 16px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      ._send_btn1:hover {
        background-color: #0056b3;
      }
      
      /* Adjust other styles as needed */
      .sold_product{
        position: absolute;
        top: -22px;
        font-size: 11px;
        background: #fa7732;
        padding: 0px 8px;
        color: #fff;
        border-radius: 4px;
      }

      ._cross_icon {
        position: absolute;
        background: #fff;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        right: 47%;
        top: -10px;
      }

      ._viewer_section{
        // width: 210px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 12px #205EC11C; height: 315px;
        .viewer{
            height: 315px
        }
        ._heading{
            padding: 15px 20px 8px;
            border-bottom: 2px solid #e3e3e3;
            @extend %dflex-space-between;
            h4{
                font-size: 21px;
            }
            ._online{
                display: flex;
                align-items: center;
                font-size: 15px;
                font-weight: 300;
                
                ._dot{
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: #04CC0C;
                    border-radius: 100px;
                }
                & ._red{
                    background: #cc0404;   
                }
            }
        }
        ._viewer_list{
            padding: 20px;
            height:  calc(315px - 58px);;
            overflow-y: auto;
            overflow-x: hidden;
            ._viewer{
                display: flex;
                .img {
                    width: 28px;
                    height: 28px;
                    img{
                        width: 100%;
                        height: 100;
                        object-fit: contain;
                    }
                }
                span{}
                p{
                    font-size: 16px;
                    color: #7E7E7E;
                    margin-left: 14px; 
                    width:calc(100% - 28px) ;
                    word-break: break-all;
                }
            }
        }
    }