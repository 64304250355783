@import './placeholder.scss';

.order_container {
    .ordercard_container {
        display: flex;
        flex-wrap: wrap;

        ._order {
            // width: 450px;
            width: 47%;
            min-height: 230px;
            background: #EFF0F2;
            margin: 10px 20px 10px 10px;
            margin-left: 0;

            ._head {
                display: flex;
                border-bottom: 1px solid #88888838;
                padding: 22px;
                margin-bottom: 20px;

                h5 {
                    font-size: 21px;
                    color: #F07721;
                    font-weight: 500;
                    text-transform: capitalize;
                }

                p {
                    font-size: 17px;
                    color: #5D5D5D;
                    font-weight: 400;
                }

                .btn {
                    width: auto !important;
                    padding: 8px 30px !important;
                    font-size: 16px !important;
                    border: 1px solid #00057D;
                    color: #00057D !important;
                    background: transparent !important;
                }

                .icon {
                    width: 52px;
                }

                .text {
                    @extend %dflex_justify_between_only;
                    width: calc(100% - 52px);
                    margin-left: 20px;

                    ._get_label {
                        background: #000564;
                        font-size: 11px;
                        height: auto;
                        padding: 6px;
                        margin: 11px 0;
                        border-radius: 4px;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }

            ._body {
                display: flex;
                padding: 0 22px 22px 22px;
                position: relative;
                align-items: center;

                ._image {
                    width: 104px;
                    height: 98px;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }
                }

                .text {
                    margin-left: 22px;
                    width: calc(100% - 110px);
                    margin-right: 12px;

                    h6 {
                        font-size: 18px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }

                    p {
                        font-size: 19px;
                        color: #5F5F5F;
                        font-weight: 400;
                        margin-bottom: 0;
                        word-break: break-all;
                    }
                }

                ._right_arrow_icon {
                    position: absolute;
                    top: 40%;
                    right: 12px;
                    transform: translateY(-50%);

                    svg {
                        width: 25px;
                        height: 25px;
                        fill: #5F5F5F;
                        cursor: pointer;
                    }
                }


            }
        }
    }
    ._blank_page{
        height: calc(100vh - 404px);
    }
}

._cancel_order_modal {
    .modal-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 12px #205EC11C;
        border-radius: 29px;
    }

    ._cancel_order {
        input {
            width: 100%;
        }

        .btn {
            padding: 8px 30px !important;
            font-size: 16px !important;
            width: 200px;
            margin: 0 auto
        }

    }

}

._order_dlivered {
    h4 {
        color: #F07721;
        text-align: center;
        margin-top: -25px;
    }

}

._order_dlivered_modal {
    @extend ._cancel_order_modal;

    ._order_dlivered {
        @extend ._cancel_order;

        hr {
            border-top: 3px solid rgb(112 112 112 / 68%);
        }

        .cancel_order {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
                width: auto;
                background-color: #000466;
                border-color: #000466;
                padding: 8px 15px !important;
                font-size: 14px !important;

            }
        }

        .small_btn {
            .btn {
                padding: 4px 17px !important;
                font-size: 14px !important;
                width: auto;
                margin: 8px;
                background: #f97732;
                border-color: #f97732;

            }
        }

        h5 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 11px;

            span {
                font-weight: 400;
            }
        }

        ._order_details {
            display: flex;

            ._order {
                width: 60%;
                padding-right: 45px;

                ._card {
                    @extend %dflex-space-between;
                    border-top: 3px solid #7070702b;
                    border-bottom: 3px solid #7070702b;
                    padding: 20px 0;

                    ._img {
                        width: 112px;
                        height: 112px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }

                    ._details {
                        width: calc(100% - 112px);
                        padding-left: 15px;

                    }
                }

                h6 {
                    font-size: 20px;
                }

                p {
                    font-size: 18px;
                    font-weight: 400;

                    // margin-bottom: 10px;
                    &._ligt {
                        font-size: 16px;
                        font-weight: 400;
                        color: #515B70;
                    }
                }
            }

            ._status {
                width: calc(100% - 60%);
                background: #F5F5F5;
                border-radius: 0px 22px 22px 0px;
                padding: 25px 10px 25px 25px;

                .delivery-status {
                    display: flex;
                    flex-direction: column;
                }

                .step {
                    display: flex;
                    // align-items: center;
                    // min-height: 84px;

                    .circle {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #02057E;
                        color: #fff;
                        position: relative;

                        &.pending {
                            border-color: #02057E;
                            color: #02057E;

                            svg {
                                display: none;
                            }
                        }

                        &.success {
                            background-color: #02057E;

                        }

                        &:after {
                            content: "";
                            position: absolute;
                            width: 3px;
                            min-height: 54px;
                            background: #00067a;
                            left: 46%;
                            top: 29px;
                        }

                    }

                    .content {
                        width: calc(100% - 40px);
                        padding-left: 18px;
                        /* Adjust based on circle size and line width */
                        padding-bottom: 10px;
                        min-height: 84px;
                        color: #515B70;
                        font-size: 16px;
                    }

                    &:last-child {
                        .circle {
                            &:after {
                                display: none;
                            }
                        }

                        .content {
                            min-height: auto;
                        }
                    }
                }


            }

        }

        ._download {
            font-size: 16px;
            @extend %dflex-space-between;
            border-top: 3px solid #7070702b;
            border-bottom: 3px solid #7070702b;
            padding: 5px 0;

            p {
                margin-bottom: 0;
            }

            span {
                width: 31px;
                height: 31px;
                background: #F07721;
                color: #fff;
                @extend %align-cneter;
                cursor: pointer;

                svg {
                    width: 18px;
                    height: 18px;
                    margin: 0 0 2px 2px;

                }
            }
        }

    }
}

.order_tab {
    // width: 400px;
    // background: pink;
    display: flex;
    justify-content: space-around;
    width: calc(100% - 67px);

    // background: deeppink;
    ._tab {
        font-size: 18px;
        color: #5D5D5D;
        font-weight: 600;
        border-bottom: 3px solid #888;
        margin-bottom: 0;
        width: 50%;
        text-align: center;
        padding-top: 12px;
        cursor: pointer;
        width: 200px;

        &.active {
            color: #F07721;
            border-color: #F07721;
        }
    }
}

._mobile_order_details {
    display: none
}

._download.show_mobile {
    display: none !important;
}

@media (max-width:991px) {
    .cancellation_mobile {
        flex-wrap: wrap !important;

        p {
            margin-bottom: 4px;
        }

        p._ligt {
            margin-bottom: 12px;
        }
    }

    .dashboard_inner {
        padding: 1rem 0.99rem;
    }

    .order_container {
        .ordercard_container {
            ._order {
                min-height: 190px;
                width: 100%;
                margin-right: 0;

                ._head {
                    padding: 15px;

                    .icon {
                        width: 25px;

                        svg {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .text {
                        h5 {
                            font-size: 15px;
                        }

                        p {
                            font-size: 13px;
                        }
                    }

                    .btn {
                        font-size: 14px !important;
                    }
                }

                ._body {
                    padding: 0 15px 15px 15px;

                    ._image {
                        width: 70px;
                        height: 70px;
                    }

                    .text {
                        h6 {
                            font-size: 14px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }

                    ._right_arrow_icon {
                        right: 6px;
                    }
                }
            }

        }

    }

    .order_tab {
        ._tab {
            font-size: 14px;
        }
    }

    .radio_btn_parent {
        display: flex;
        align-items: center
    }

    ._order_dlivered {
        padding: 0 !important;

        .close_icon {
            display: none;
        }

        h5 {
            font-size: 16px;
        }

        h4 {
            text-align: left;
            color: #F07721;
            display: flex;

            .back_icon {
                color: #000;
                font-size: 17px;
                margin-right: 15px;
                cursor: pointer;

            }
        }

        ._order_details {
            flex-wrap: wrap;
            display: none !important;

            ._order {
                width: 100%;
                padding-right: 0px;
                display: none;

                ._card {
                    padding: 11px 0;

                    ._img {
                        width: 70px;
                        height: 70px;
                    }

                    ._details {
                        width: calc(100% - 90px);
                    }
                }
            }

            ._status {
                width: 100%;
                background: #ffffff;
                border-radius: 0;
                padding: 20px 0;

                // display: none;
                .delivery-status {
                    .step {
                        .circle {
                            width: 23px;
                            height: 23px;
                        }

                        :after {
                            min-height: 28px;
                            top: 20px;
                        }

                        .content {
                            min-height: 50px;
                        }
                    }
                }
            }
        }

        ._mobile_order_details {
            display: block;

            .product_banner {
                background: #EFF0F2;
                text-align: center;
                padding: 34px 0;

                ._img {
                    width: 95px;
                    height: 95px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
            }

            ._orders_details {
                h5 {
                    font-size: 20px;
                    color: #F07721
                }

                p {
                    display: flex;

                    span {
                        width: 50%;
                        font-weight: 300;
                        word-break: break-all;

                        &:nth-child(2) {
                            text-align: right;
                            font-weight: 400
                        }
                    }

                }
            }

            ._order_status {
                border-top: 3px solid #eeeeee75;
                border-bottom: 3px solid #eeeeee75;
                padding: 40px 0;

                ._status {
                    .delivery-status {
                        display: flex;
                        justify-content: space-between;
                        max-width: 400px;
                        margin: 0 auto;

                        .step {
                            width: 100px;

                            .circle {
                                position: relative;
                                cursor: pointer;

                                &:after {
                                    content: '';
                                    width: 47px;
                                    height: 1px;
                                    position: absolute;
                                    background: #000679;
                                    top: 50%;
                                    left: 42px;

                                }

                            }

                            &:last-child {
                                .circle {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }

                            .content {
                                font-size: 12px;
                                font-weight: 500;
                                margin-top: 10px;
                                width: 65px;
                                margin-right: 10px;
                            }
                        }

                    }


                }

            }

            ._address {
                h6 {
                    font-size: 20px;
                }

                p {
                    font-size: 19px;
                    margin-bottom: 0;

                    &:last-child {
                        font-size: 14px;
                        color: #5D5D5D;
                    }
                }

            }
        }
    }

    .___hide_mobile_modals {

        // margin-top: 62px;
        // height: calc(100vh - 140px);
        .modal-dialog {

            // margin: 0px 0 0 0;
            .modal-content {
                // box-shadow: 0px 0px 0 rgba(32, 94, 193, 0.1098039216);
                // border-radius: 0px;
                // border: none;

            }
        }

    }
}

.circle.success.danger {
    background-color: red !important;
    border-color: red !important;
}