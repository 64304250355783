@import '../StyleComponent/placeholder.scss';

.landing_page {
    .btn-primary {
        background: #FF4F01;
        border-color: #FF4F01;
        border-radius: 100px;
        min-height: 48px;
        line-height: 2;

    }

    .btn-primary:hover {
        border-color: #ffff;
        background: transparent;
    }

    ._light_heading {
        font-size: 2.75rem;
        margin-bottom: 0;
        font-weight: 300;
        margin-bottom: 12px;
    }

    ._dark_heading {
        font-size: 2.75rem;
        color: #FF4F01;
        margin-bottom: 0;
        font-weight: 600;
        margin-bottom: 12px;
    }

    ._qr_sec {
        h3 {
            font-size: 1.175rem;
            text-transform: uppercase;
            margin-bottom: 25px;

        }
    }

    .video-container {
        position: relative;
        // padding-top: 56.25%; /* 16:9 Aspect Ratio */
        // height: 0;
        height: 100%;
        overflow: hidden;
    }

    .react-player,
    .html-video {
        border-radius: 30px;
        height: 99%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 3px;
        width: 99%;
    }

    .video_text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0px;
    }


    .header_section {
        @extend %dflex-space-between;

        padding: 20px 80px;
        background: #000;

        .btn-primary {
            min-width: 212px;
            font-weight: 500;
        }
    }

    ._banner {
        padding: 0 80px;
        @extend %dflex-space-between;
        background: #000;
        height: calc(100vh - 206px);
        position: relative;

        ._left {
            width: 40%;
            position: relative;

            .frame-video {
                position: absolute;
                left: 130px;
                top: 12px;
                width: 222px;
                height: 95%;
                border-radius: 28px;
                background: black;

            }

        }

        ._right {
            width: 60%;

            ._text {
                color: #fff;
                width: 457px;
                margin-right: calc(100% - 457px);

                h1 {
                    font-size: 2.8rem;
                    font-weight: 500;
                    line-height: 1.5;

                    span {
                        color: #FF4F01;
                    }
                }

                p {
                    font-size: 1.25rem;
                    color: #CCCCCC;
                    margin: 28px 0 67px 0;
                }

            }
        }

        .laptop_hand_img {
            position: absolute;
            bottom: 0px;
            right: 0%;
            width: 336px;
            height: 300px;
        }

        .bottom-banner {
            position: absolute;
            bottom: -32px;
            left: 50%;
            transform: translate(-50%, 0px);

            .arrow_loty {
                position: absolute;
                left: 53%;
                top: 0%;
                transform: translate(-50%, -78%);

                svg {
                    cursor: auto;
                }
            }
        }

    }

    ._what_product {
        padding: 110px 80px 34px;
        @extend %dflex-space-between;

        ._left {
            width: 50%;
            padding-right: 20px;

            ._img {
                position: relative;

                img {
                    z-index: 9;
                    position: relative;
                }

                .sving_animation {
                    position: absolute;
                    top: 54px;
                    left: 5px
                }
            }
        }

        ._right {
            width: 50%;
            padding-right: 60px;
            padding-left: 43px;

            ._text {
                ._light_heading {
                    color: #1B1B1B;
                }

                p {
                    font-size: 1.165rem;
                    color: #000000;
                    font-weight: 200;
                    line-height: 1.8;
                    letter-spacing: 1px;
                    margin-bottom: 35px
                }

            }
        }
    }

    ._experience_live_auction {
        ._banner {
            height: auto;
            padding: 80px;

            ._left {
                order: 2;

                .frame-video1 {
                    position: absolute;
                    left: 16px;
                    top: 14px;
                    width: 263px;
                    height: 95%;
                    border-radius: 45px;
                    background: black;

                    .react-player,
                    .html-video {
                        border-radius: 40px;
                    }

                }
            }

            ._right {
                order: 1;

                ._qr_sec {
                    position: relative;

                    .arrow_indicator {
                        position: absolute;
                        left: 176px;
                        bottom: 0;

                    }
                }
            }

            .bottom-banner {
                .arrow_loty {
                    transform: translate(-50%, -78%) rotate(180deg);
                }
            }
        }

    }

    ._advantage {
        padding: 110px 80px 34px;

        .heading {
            text-align: center;
            font-weight: 300;
            font-size: 2.625rem;

            p {
                font-weight: 600;
                font-size: 3.125rem;
                position: relative;

            }

            ::after {
                content: " ";
                position: absolute;
                width: 186px;
                height: 6px;
                background: linear-gradient(270deg, #000, #FF4F01);
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 20px);
            }
        }

        .advantage_block_parent {
            display: flex;
            margin-top: 65px;

            .advantage_block {

                width: calc(100% / 3 - 26px);
                // background: rgba(255, 192, 203, 0.1215686275);
                margin: 26px;
                text-align: center;
                position: relative;

                ._img {
                    margin: 0 auto;
                }

                ._hading {
                    font-weight: 500;
                    font-size: 1.75rem;
                    position: relative;
                    margin-top: 24px;

                }

                ._hading::after {
                    content: " ";
                    position: absolute;
                    width: 82px;
                    height: 3px;
                    background: #000;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 20px);
                }

                ._discription {
                    margin-top: 55px;
                    font-size: 1.165rem;
                    font-weight: 300;
                    line-height: 1.8;
                    letter-spacing: 1px;
                    margin-bottom: 35px;
                    color: #1B1B1B;
                    opacity: 0.8;
                }
            }
        }

    }

    ._landing_footer {
        background: #000;
        padding: 54px 80px 30px;

        .footer_parent {
            display: flex;
            color: #fff;

            .footer_left_parent {
                width: 50%;

            }

            .footer_right_parent {
                width: 50%;
                @extend %dflex-space-between;
                align-items: flex-start;
                justify-content: space-around;

                .footer-menu {
                    h6 {
                        font-size: 1.375rem;
                        font-weight: 500;
                        margin-bottom: 45px;
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 0;

                        li {
                            margin: 20px 0;

                            a {
                                font-size: 1.063rem;
                                color: #A5A5A5;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 767px) {
                flex-wrap: wrap;

                .footer_left_parent,
                & .footer_right_parent {
                    width: 100%;
                }

                .footer_left_parent {
                    .footer-widget {
                        .subscribe-form {
                            .texbox {
                                width: 85%;
                            }
                        }
                    }
                }

                .footer_right_parent {
                    margin-top: 30px;

                }

            }
        }

        .bottom {
            p {
                margin-bottom: 0;
                font-size: 1.063rem;
                color: #A5A5A5;
                margin-top: 24px;

                a {
                    color: #A5A5A5;
                    text-decoration: none;
                    margin: 0 12px;
                }
            }
        }
    }

}


@media (max-width:991px) {
    .landing_page {
        ._light_heading {
            font-size: 2rem;
        }

        ._dark_heading {
            font-size: 2rem;
        }

        ._qr_sec {
            margin-bottom: 100px;
        }

        ._banner {
            flex-wrap: wrap;
            height: calc(100%);

            ._left {
                width: 367px;
                margin: 0 auto;

                .frame-video {
                    position: absolute;
                    left: 130px;
                    top: 12px;
                    width: 222px;
                    height: 95%;
                    border-radius: 28px;
                    background: black;

                }

            }

            ._right {
                width: 100%;

                ._text {
                    width: 367px;
                    margin: 0 auto;
                    text-align: center;

                    h1 {
                        font-size: 2rem;

                        span {
                            color: #FF4F01;
                        }
                    }

                    p {
                        font-size: 1rem;
                        margin: 28px 0;
                    }

                }
            }

            .laptop_hand_img {
                width: 200px;
            }

            .bottom-banner {
                position: absolute;
                bottom: -32px;
                left: 50%;
                transform: translate(-50%, 0px);

                .arrow_loty {
                    position: absolute;
                    left: 53%;
                    top: 0%;
                    transform: translate(-50%, -78%);
                }
            }

        }

        ._what_product {
            flex-wrap: wrap;

            ._left {
                width: 100%;
            }

            ._right {
                width: 100%;
                padding-right: 0px;
                padding-left: 0px;

                ._text {
                    text-align: center;
                }
            }
        }

        ._experience_live_auction {
            ._banner {
                height: auto;
                padding: 80px;

                ._left {
                    order: 2;
                    width: 488px;

                    .frame-video1 {
                        position: absolute;
                        left: 16px;
                        top: 14px;
                        width: 263px;
                        height: 95%;
                        border-radius: 45px;
                        background: black;

                        .react-player,
                        .html-video {
                            border-radius: 40px;
                        }

                    }
                }

                ._right {
                    order: 1;

                    ._qr_sec {
                        position: relative;

                        .arrow_indicator {
                            position: absolute;
                            left: 176px;
                            bottom: 0;

                        }
                    }
                }

                .bottom-banner {
                    .arrow_loty {
                        transform: translate(-50%, -78%) rotate(180deg);
                    }
                }
            }

        }

        ._advantage {
            padding: 110px 80px 34px;

            .heading {
                font-size: 2rem;

                p {
                    font-size: 3rem;
                }

                ::after {
                    content: " ";
                    position: absolute;
                    width: 186px;
                    height: 6px;
                    background: linear-gradient(270deg, #000, #FF4F01);
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 20px);
                }
            }

            .advantage_block_parent {
                flex-wrap: wrap;

                .advantage_block {
                    width: calc(100%);
                }
            }

        }

        ._landing_footer {
            background: #000;
            padding: 54px 80px 30px;

            .footer_parent {
                display: flex;
                color: #fff;

                .footer_left_parent {
                    width: 50%;

                }

                .footer_right_parent {
                    width: 50%;
                    @extend %dflex-space-between;
                    align-items: flex-start;

                    .footer-menu {
                        h6 {
                            font-size: 1.375rem;
                            font-weight: 500;
                            margin-bottom: 45px;
                        }

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                margin: 20px 0;

                                a {
                                    font-size: 1.063rem;
                                    color: #A5A5A5;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;

                    .footer_left_parent,
                    & .footer_right_parent {
                        width: 100%;
                    }

                    .footer_left_parent {
                        .footer-widget {
                            .subscribe-form {
                                .texbox {
                                    width: 85%;
                                }
                            }
                        }
                    }

                    .footer_right_parent {
                        margin-top: 30px;

                    }

                }
            }

            .bottom {
                p {
                    margin-bottom: 0;
                    font-size: 1.063rem;
                    color: #A5A5A5;
                    margin-top: 24px;

                    a {
                        color: #A5A5A5;
                        text-decoration: none;
                        margin: 0 12px;
                    }
                }
            }
        }

    }
}

@media (max-width:540px) {
    .landing_page {
        ._qr_sec {
            margin-bottom: 30px;
        }

        .react-player,
        .html-video {
            border-radius: 24px;
            height: 99%;
            left: 0;
            top: 3px;
            width: 99%;
        }

        .header_section {
            padding: 20px 15px;

            img {
                height: 40px !important;
            }

            .btn-primary {
                min-width: 129px;
                font-weight: 500;
                min-height: 36px;
                line-height: 2;
            }
        }

        ._banner {
            padding: 40px 35px 85px;

            ._left {
                width: 244px;
                margin-bottom: 30px;

                .frame-video {
                    left: 87px;
                    top: 7px;
                    width: 151px;
                    border-radius: 24px
                }
            }

            ._right {
                ._text {
                    width: 100%;
                }
            }

            .laptop_hand_img {
                width: 122px;
                height: 122px;
                z-index: 9
            }

            .bottom-banner {
                bottom: -24px;
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        ._what_product {
            padding: 73px 16px 34px;

            ._left {
                ._img {
                    .sving_animation {
                        position: absolute;
                        top: -32px;
                    }
                }
            }

        }

        ._experience_live_auction {
            ._banner {
                padding: 80px 35px;

                ._left {
                    width: 239px;

                    .frame-video1 {
                        left: 9px;
                        top: 6px;
                        width: 127px;
                        border-radius: 14px;

                        .html-video {
                            border-radius: 13px;
                        }
                    }
                }

                ._right {
                    ._text {
                        ._qr_sec {
                            .arrow_indicator {
                                width: 65px;
                                left: 200px;
                            }
                        }
                    }
                }
            }
        }

        ._advantage {
            padding: 73px 16px 34px;

            .advantage_block_parent {
                .advantage_block {
                    ._hading {
                        font-size: 1.5rem;
                    }
                }
            }

            .heading {
                p {
                    font-size: 2rem;
                }
            }
        }

        ._landing_footer {
            padding: 54px 35px 30px;

            .footer_parent {
                .footer_left_parent {
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                }

                .footer_right_parent {
                    margin-top: 30px;
                    flex-wrap: wrap;
                    text-align: center;

                    .footer-menu {
                        width: 100%;

                        h6 {
                            margin-bottom: 8px;
                        }

                        ul {
                            margin-bottom: 35px;

                            li {
                                margin: 6px 0;

                                a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                }
                            }
                        }
                    }

                }
            }

            .bottom p {
                font-size: .7rem;
                text-align: center;
            }
        }
    }
}